import React, { FC, ReactElement, useContext, useEffect } from 'react';
import { Redirect, Router, RouteComponentProps, useNavigate } from '@reach/router';
import queryString from 'query-string';
import './App.scss';
import 'antd/dist/antd.css';
import { AuthContext, AuthProvider } from './context/AuthContext/AuthContext';
import { NotificationProvider } from './context/NotificationContext/NotificationContext';
import { OverlayProvider } from './context/OverlayContext/OverlayContext';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Login } from './pages/Login/Login';
import { News } from './pages/News/News';
import { Notifications } from './pages/Notifications/Notifications';
import { Profile } from './pages/Profile/Profile';
import { Routes } from './pages/Routes/Routes';
import { Settings } from './pages/Settings/Settings';
import { Signup } from './pages/Signup/Signup';
import { Support } from './pages/Support/Support';
import { Users } from './pages/Users/Users';
import { ResetPassword } from './pages/ResetPassword/ResetPassword';

type ProtectedRouteProps = {
  component: FC<RouteComponentProps>;
  path: string;
};

const pathname = Object.keys(queryString.parse(location.pathname)); // eslint-disable-line

const ProtectedRoute: FC<ProtectedRouteProps> = ({ component: Component, path }) => {
  const navigate = useNavigate();
  const { session } = useContext(AuthContext);
  const activeSession = !!session.token && !!session.uid;

  useEffect(() => {
    if (!session.uid) navigate('/');
  }, [session.uid]);

  if (!activeSession && pathname[0] !== '/' && pathname[0] !== '/sign-up') {
    return <Redirect from={path} to="/" noThrow />;
  }

  return <Component path={path} />;
};

const App = (): ReactElement => {
  return (
    <AuthProvider>
      <NotificationProvider>
        <OverlayProvider>
          <div className="bg-brand-light-gray w-full h-full">
            <Router className="h-full">
              <Login path="/" />
              <Signup path="/sign-up" />
              <ResetPassword path="/reset-password" />

              <ProtectedRoute component={Dashboard} path="/dashboard" />
              <ProtectedRoute component={Routes} path="/routes" />
              <ProtectedRoute component={Support} path="/support" />
              <ProtectedRoute component={News} path="/news" />
              <ProtectedRoute component={Users} path="/users" />
              <ProtectedRoute component={Settings} path="/settings" />
              <ProtectedRoute component={Profile} path="/profile" />
              <ProtectedRoute component={Notifications} path="/notifications" />
            </Router>
          </div>
        </OverlayProvider>
      </NotificationProvider>
    </AuthProvider>
  );
};

export default App;
