import { useEffect, useReducer } from 'react';
import normalize from 'json-api-normalizer';
import { fetchRoutes, fetchSingleRoute } from '../../services/routeService/routeService';
import {
  DeliveryDictionary,
  DriverDictionary,
  FetchType,
  NullRoute,
  RoutesAction,
  RoutesHookReturn,
  RoutesState,
} from './useRoutes.types';
import { extractError } from '../../utils/errorUtils';

const reducer = (state: RoutesState, action: RoutesAction): RoutesState => {
  switch (action.type) {
    case 'CLEAR_ACTIVE_ROUTE':
      return { ...state, activeRoute: NullRoute, activeRouteId: null };
    case 'REQUEST_MORE_ROUTES':
      return { ...state, fetchingMoreRoutes: true };
    case 'REQUEST_MORE_ROUTES_SUCCESS':
      return {
        ...state,
        fetchingMoreRoutes: false,
        routes: [...state.routes, ...action.payload.routes],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    case 'REQUEST_ROUTES':
      return { ...state, loading: true };
    case 'REQUEST_ROUTES_SUCCESS':
      return {
        ...state,
        loading: false,
        routes: action.payload.routes,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    case 'SET_ACTIVE_ROUTE':
      return {
        ...state,
        activeRoute: action.payload.route,
        deliveriesLoading: false,
        deliveryDictionary: action.payload.deliveryDictionary,
        driverDictionary: action.payload.driverDictionary,
      };
    case 'SET_ACTIVE_ROUTE_ID':
      return { ...state, activeRouteId: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'SET_LOADING':
      return { ...state, error: null, loading: action.payload };
    case 'SINGLE_ROUTE_REQUEST':
      return { ...state, error: null, deliveriesLoading: true };
    default:
      return state;
  }
};

const initialState: RoutesState = {
  activeRoute: NullRoute,
  activeRouteId: null,
  deliveriesLoading: false,
  deliveryDictionary: {} as DeliveryDictionary,
  driverDictionary: {} as DriverDictionary,
  error: null,
  fetchingMoreRoutes: false,
  loading: false,
  routes: [],
  currentPage: 1,
  totalPages: 1,
};

const requestDispatchScenarios: {
  [key in FetchType]: 'REQUEST_ROUTES' | 'REQUEST_MORE_ROUTES';
} = {
  getMore: 'REQUEST_MORE_ROUTES',
  initial: 'REQUEST_ROUTES',
};

const successDispatchScenarios: {
  [key in FetchType]: 'REQUEST_ROUTES_SUCCESS' | 'REQUEST_MORE_ROUTES_SUCCESS';
} = {
  getMore: 'REQUEST_MORE_ROUTES_SUCCESS',
  initial: 'REQUEST_ROUTES_SUCCESS',
};

export const useRoutes = (): RoutesHookReturn => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.activeRouteId) getSingleRoute(state.activeRouteId);
  }, [state.activeRouteId]);

  const clearActiveRoute = () => {
    dispatch({ type: 'CLEAR_ACTIVE_ROUTE' });
  };

  const getRoutes = async (type: FetchType): Promise<void> => {
    dispatch({ type: requestDispatchScenarios[type] });
    try {
      const page = type === 'initial' ? '1' : (state.currentPage + 1).toString();
      const response = await fetchRoutes(page);
      const totalPages = parseInt(response.headers['total-pages'], 10);
      const currentPage = parseInt(response.headers['current-page'], 10);

      dispatch({
        type: successDispatchScenarios[type],
        payload: { routes: response.data.data, currentPage, totalPages },
      });
    } catch {
      dispatch({ type: 'SET_ERROR', payload: 'Error getting route information.' });
    }
  };

  const getSingleRoute = async (routeId: string): Promise<void> => {
    dispatch({ type: 'SINGLE_ROUTE_REQUEST' });

    try {
      const response = await fetchSingleRoute(routeId);
      const { delivery: deliveryDictionary, driver: driverDictionary } = normalize(response.data);

      dispatch({
        type: 'SET_ACTIVE_ROUTE',
        payload: { route: response.data.data, driverDictionary, deliveryDictionary },
      });
    } catch (e) {
      dispatch({ type: 'SET_ERROR', payload: extractError(e) });
    }
  };

  const setActiveRouteId = (routeId: string): void => {
    dispatch({ type: 'SET_ACTIVE_ROUTE_ID', payload: routeId });
  };

  return { ...state, clearActiveRoute, getRoutes, setActiveRouteId };
};
