import { AxiosResponse } from 'axios';
import { baseService } from '../baseService';

interface LoginResponse {
  data: User;
  headers: {
    'access-token': string;
  };
}

export const postLogin = async (values: {
  email: string;
  password: string;
}): Promise<AxiosResponse<LoginResponse>> => baseService.post('customer/sign_in', values);

export const deleteSession = async (): Promise<AxiosResponse<any>> =>
  baseService.delete('/customer/sign_out');
