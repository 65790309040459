import { useContext, useReducer } from 'react';
import { Action, ResetPasswordHookReturn, State } from './useResetPassword.types';
import {
  patchPasswordForLoggedInUser,
  postPasswordResetRequest,
  putNewPassword,
} from '../../services/userService/userService';
import { extractError } from '../../utils/errorUtils';
import { AuthContext } from '../../context/AuthContext/AuthContext';

export const RESET_PASSWORD_SUCCESS_MESSAGE = 'Your password has been successfully updated.';

const initialState: State = {
  error: null,
  loading: false,
  message: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'REQUEST':
      return { ...state, loading: true, error: null };
    case 'SUCCESS':
      return { ...state, loading: false, error: null };
    case 'TOKEN_REQUEST_SUCCESS':
      return { ...state, loading: false, message: action.payload };
    default:
      return state;
  }
};

export const useResetPassword = (): ResetPasswordHookReturn => {
  const {
    userInfo: { id: userId },
  } = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const requestResetToken = async (email: string): Promise<void> => {
    dispatch({ type: 'REQUEST' });
    try {
      const response = await postPasswordResetRequest(email);
      const { message, success } = response.data;
      if (!success) throw new Error('There was an error');
      dispatch({ type: 'TOKEN_REQUEST_SUCCESS', payload: message });
    } catch (e) {
      dispatch({ type: 'FAIL', payload: extractError(e) });
    }
  };

  const resetPassword = async (
    password: string,
    passwordConfirmation: string,
    resetPasswordToken: string,
  ): Promise<void> => {
    dispatch({ type: 'REQUEST' });
    try {
      const response = await putNewPassword(password, passwordConfirmation, resetPasswordToken);
      const { message, success } = response.data;
      if (!success) throw new Error('There was an error');
      dispatch({ type: 'TOKEN_REQUEST_SUCCESS', payload: message });
    } catch (e) {
      dispatch({ type: 'FAIL', payload: extractError(e) });
    }
  };

  const changePasswordForAuthenticatedUser = async (
    currentPassword: string,
    password: string,
    passwordConfirmation: string,
    successCallback?: () => void,
  ): Promise<void> => {
    dispatch({ type: 'REQUEST' });
    try {
      await patchPasswordForLoggedInUser(userId, currentPassword, password, passwordConfirmation);
      dispatch({ type: 'SUCCESS' });
      if (successCallback) successCallback();
    } catch (e) {
      dispatch({ type: 'FAIL', payload: extractError(e) });
    }
  };

  return {
    ...state,
    changePasswordForAuthenticatedUser,
    requestResetToken,
    resetPassword,
    RESET_PASSWORD_SUCCESS_MESSAGE,
  };
};
