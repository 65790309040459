import React, { FC } from 'react';
import { TruckIcon } from '../../assets/icons';

export const EmptyRouteDetail: FC = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="mb-4">
        <TruckIcon />
      </div>
      <span className="text-brand-inactive-gray">Select a route to view more information.</span>
    </div>
  );
};
