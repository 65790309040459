import { useReducer } from 'react';
import normalize from 'json-api-normalizer';
import {
  deleteUser,
  getUsers,
  postUserInvite,
  UserInviteValues,
} from '../../services/userService/userService';
import { removeUserFromState } from '../../utils/userUtils';
import { extractError } from '../../utils/errorUtils';
import {
  CustomerDictionary,
  UserDictionary,
  UsersAction,
  UsersHookReturn,
  UsersState,
} from './useUsers.types';
import { normalizePhone } from '../../utils/phoneUtils';

const initialState: UsersState = {
  customerDictionary: {} as CustomerDictionary,
  error: null,
  loading: false,
  userDictionary: {} as UserDictionary,
  users: [] as User[],
  totalNumberOfUsers: 0,
  totalNumberOfPages: 1,
  currentPage: 1,
};

const reducer = (state: UsersState, action: UsersAction): UsersState => {
  switch (action.type) {
    case 'FAIL':
      return { ...state, error: action.payload, loading: false };
    case 'FETCH_USERS_SUCCESS':
      return { ...state, loading: false, error: null, ...action.payload };
    case 'REMOVE_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        users: removeUserFromState(action.payload, state.users),
      };
    case 'REQUEST':
      return { ...state, loading: true };
    case 'SUCCESS':
      return { ...state, loading: false, error: null };
    default:
      return state;
  }
};

export const useUsers = (): UsersHookReturn => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const inviteUser = async (
    values: UserInviteValues,
    successCallback?: () => void,
  ): Promise<void> => {
    const { phone, ...restValues } = values;
    dispatch({ type: 'REQUEST' });
    try {
      await postUserInvite({ phone: normalizePhone(phone), ...restValues });
      dispatch({ type: 'SUCCESS' });
      if (successCallback) successCallback();
    } catch (e) {
      dispatch({ type: 'FAIL', payload: extractError(e) });
    }
  };

  const removeUser = async (userId: string, successCallback?: () => void) => {
    dispatch({ type: 'REQUEST' });
    try {
      await deleteUser(userId);
      dispatch({ type: 'REMOVE_USER_SUCCESS', payload: userId });
      if (successCallback) successCallback();
    } catch (e) {
      dispatch({ type: 'FAIL', payload: extractError(e) });
    }
  };

  const fetchUsers = async (config?: { page: number }) => {
    const pageNumber = config?.page.toString() ?? '1';
    dispatch({ type: 'REQUEST' });
    try {
      const { data, headers } = await getUsers(pageNumber);
      const { customer: customerDictionary, user: userDictionary } = normalize(data);

      dispatch({
        type: 'FETCH_USERS_SUCCESS',
        payload: {
          customerDictionary,
          userDictionary,
          users: data.data,
          totalNumberOfPages: headers['total-pages'],
          totalNumberOfUsers: headers['total-count'],
          currentPage: headers['current-page'],
        },
      });
    } catch (e) {
      dispatch({ type: 'FAIL', payload: extractError(e) });
    }
  };

  return { ...state, inviteUser, fetchUsers, removeUser };
};
