import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = {
  className?: string;
  color?: string;
  size?: number;
};

export const FilledStar: FC<Props> = ({ className, color = colors.black, size = 25 }) => {
  return (
    <svg viewBox="0 0 25 25" width={size} height={size} className={className}>
      <g transform="matrix(1.0416666666666667,0,0,1.0416666666666667,0,0)">
        <path
          d="M23.555,8.729a1.505,1.505,0,0,0-1.406-.98H16.062a.5.5,0,0,1-.472-.334L13.405,1.222a1.5,1.5,0,0,0-2.81,0l-.005.016L8.41,7.415a.5.5,0,0,1-.471.334H1.85A1.5,1.5,0,0,0,.887,10.4l5.184,4.3a.5.5,0,0,1,.155.543L4.048,21.774a1.5,1.5,0,0,0,2.31,1.684l5.346-3.92a.5.5,0,0,1,.591,0l5.344,3.919a1.5,1.5,0,0,0,2.312-1.683l-2.178-6.535a.5.5,0,0,1,.155-.543l5.194-4.306A1.5,1.5,0,0,0,23.555,8.729Z"
          fill={color}
          stroke="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0"
        />
      </g>
    </svg>
  );
};
