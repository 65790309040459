import React, { Dispatch, FC, SetStateAction } from 'react';
import { FilledStar, HollowStar } from '../../assets/icons';
import { colors } from '../../styles/colors';

type Props = {
  rating: number;
  setRating: Dispatch<SetStateAction<number>>;
};

export const StarRating: FC<Props> = ({ rating, setRating }) => {
  const starArray = ['star', 'star', 'star', 'star', 'star'];
  const STAR_SIZE = 30;

  const handleClick = (newRating: number) => {
    setRating(newRating);
  };

  return (
    <div className="flex flex-row mt-1">
      {starArray.map((_, index) => {
        const filled = index + 1 <= Math.floor(rating);
        return (
          <button
            key={index.toString()}
            type="button"
            onClick={() => handleClick(index + 1)}
            className="mr-2"
            style={{ outline: 'none' }}
          >
            {filled ? (
              <FilledStar color={colors.orange} size={STAR_SIZE} />
            ) : (
              <HollowStar color={colors.orange} size={STAR_SIZE} />
            )}
          </button>
        );
      })}
    </div>
  );
};
