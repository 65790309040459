import React, { MouseEvent, FC, useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button } from '../../components/Button/Button';
import { FormLabel } from '../../components/FormLabel/FormLabel';
import { FormikInput } from '../../components/FormikInput/FormikInput';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import { UserFormValues } from '../../services/userService/userService';

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  company: yup.string(),
  email: yup.string().required('Please enter your email'),
  phone: yup.string().max(10, 'Please enter a valid phone number'),
});

export const ProfileForm: FC = () => {
  const [editing, setEditing] = useState(false);
  const {
    associatedCompany,
    updateUser,
    userInfo: {
      id: userId,
      attributes: { firstName, lastName, email, phone },
    },
  } = useContext(AuthContext);

  const initialValues: UserFormValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    company: associatedCompany?.attributes.name ?? '',
    email: email || '',
    phone: phone || '',
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleEditClick = (event: MouseEvent) => {
    event.preventDefault();
    setEditing(true);
  };

  const handleSubmit = async (values: UserFormValues): Promise<void> => {
    updateUser(userId, values, () => setEditing(false));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="mb-4">
          <FormLabel title="First Name" required={editing} />
          {editing && <FormikInput id="firstName" />}
          {!editing && <span className="block">{firstName}</span>}
        </div>

        <div className="mb-4">
          <FormLabel title="Last Name" required={editing} />
          {editing && <FormikInput id="lastName" />}
          {!editing && <span className="block">{lastName}</span>}
        </div>

        {!!initialValues.company && (
          <div className="mb-4">
            <FormLabel title="Company" />
            {editing && <FormikInput id="company" disabled />}
            {!editing && <span className="block">{initialValues.company}</span>}
          </div>
        )}

        <div className="mb-6">
          <FormLabel title="Email Address" required={editing} />
          {editing && <FormikInput id="email" />}
          {!editing && <span className="block">{email}</span>}
        </div>

        <div className="mb-6">
          <FormLabel title="Phone Number" />
          {editing && <FormikInput id="phone" />}
          {!editing && <span className="block">{phone ?? 'No phone number available'}</span>}
        </div>

        {!editing && <Button label="EDIT PROFILE" onClick={handleEditClick} />}

        <div className="justify-start">
          {editing && (
            <div className="mr-4 inline">
              <Button label="SAVE" type="submit" />
            </div>
          )}
          {editing && <Button label="CANCEL" type="button" onClick={handleCancel} theme="light" />}
        </div>
      </Form>
    </Formik>
  );
};
