import React, { ButtonHTMLAttributes, FC, MouseEvent } from 'react';
import './Button.scss';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  label: string;
  onClick?: (event: MouseEvent) => void;
  theme?: 'light' | 'normal';
  loading?: boolean;
};

const baseButtonStyle =
  'py-1 px-4 rounded-full cursor-pointer focus:shadow-outline focus:outline-none';
const baseTextStyle = 'text-sm leading-none';
const lightButtonTheme = 'bg-white border border-brand-orange';
const normalButtonTheme = 'bg-brand-orange border-border-brand-orange';
const whiteText = 'text-white';
const orangeText = 'text-brand-orange';

export const Button: FC<Props> = ({
  disabled = false,
  label,
  onClick = () => {},
  loading,
  style,
  theme = 'normal',
  type = 'button',
}) => {
  const disabledStyling = theme === 'normal' ? 'bg-brand-faded-orange' : 'opacity-50';
  const buttonTheme = theme === 'normal' ? normalButtonTheme : lightButtonTheme;
  const textTheme = theme === 'normal' ? whiteText : orangeText;

  return (
    <button
      className={`${baseButtonStyle} ${buttonTheme} ${disabled ? disabledStyling : ''}`}
      disabled={disabled}
      onClick={onClick}
      type={type} // eslint-disable-line
      style={style}
    >
      <span className={`${baseTextStyle} ${textTheme}`}>{loading ? 'LOADING...' : label}</span>
    </button>
  );
};
