import { AxiosResponse } from 'axios';
import { baseService } from '../baseService';

interface SettingsResponse {
  data: User;
  headers: {
    'access-token': string;
  };
}

export const updateHomescreen = async (
  home_screen: InitialHomescreen,
): Promise<AxiosResponse<SettingsResponse>> =>
  baseService.put('users/settings', {
    user: {
      settings: {
        home_screen,
      },
    },
  });

export const updateEmailNotifications = async (
  email_notifications: boolean,
): Promise<AxiosResponse<SettingsResponse>> =>
  baseService.put('users/settings', {
    user: {
      settings: {
        email_notifications,
      },
    },
  });

export const updateTextNotifications = async (
  text_notifications: boolean,
): Promise<AxiosResponse<SettingsResponse>> =>
  baseService.put('users/settings', {
    user: {
      settings: {
        text_notifications,
      },
    },
  });
