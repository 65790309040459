import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import { RouteDetail } from './RouteDetail';
import { RouteList } from './RouteList';
import { useRoutes } from '../../hooks/useRoutes/useRoutes';

type Props = RouteComponentProps;

export const Routes: FC<Props> = ({ path }) => {
  const {
    activeRoute,
    activeRouteId,
    clearActiveRoute,
    currentPage,
    deliveriesLoading,
    deliveryDictionary,
    driverDictionary,
    fetchingMoreRoutes,
    getRoutes,
    loading,
    routes,
    setActiveRouteId,
    totalPages,
  } = useRoutes();

  useEffect(() => {
    getRoutes('initial');
  }, []);

  return (
    <MainLayout path={path}>
      <div className="lg:flex flex-row flex-1">
        <RouteList
          activeRouteId={activeRouteId}
          loading={loading}
          routes={routes}
          setActiveRouteId={setActiveRouteId}
          totalPages={totalPages}
          getRoutes={getRoutes}
          currentPage={currentPage}
          fetchingMoreRoutes={fetchingMoreRoutes}
        />
        <RouteDetail
          activeRoute={activeRoute}
          activeRouteId={activeRouteId}
          clearActiveRoute={clearActiveRoute}
          deliveriesLoading={deliveriesLoading}
          deliveryDictionary={deliveryDictionary}
          driverDictionary={driverDictionary}
        />
      </div>
    </MainLayout>
  );
};
