import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';

type Props = RouteComponentProps;

export const Signup: FC<Props> = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <span>Sign up page</span>
    </div>
  );
};
