import React, { FC, createContext, useReducer } from 'react';
import {
  getNotifications,
  getUnreadNotifications,
} from '../../services/notificationService/notificationService';
import { extractError } from '../../utils/errorUtils';
import { Action, FetchType, Provider, State } from './NotificationContext.types';

export const NotificationContext = createContext<Provider>({} as Provider);

const initialState: State = {
  error: null,
  fetchingNotifications: false,
  fetchingMoreNotifications: false,
  loading: false,
  notifications: [],
  unreadNotifications: false,
  currentPage: 1,
  totalPages: 1,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FAIL':
      return { ...state, error: action.payload, fetchingNotifications: false, loading: false };
    case 'REQUEST':
      return { ...state, error: null, loading: true };
    case 'REQUEST_MORE_NOTIFICATIONS':
      return { ...state, error: null, fetchingMoreNotifications: false };
    case 'REQUEST_MORE_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        error: null,
        fetchingNotifications: false,
        notifications: [...state.notifications, ...action.payload.notifications],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    case 'REQUEST_NOTIFICATIONS':
      return { ...state, error: null, fetchingNotifications: true };
    case 'REQUEST_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        error: null,
        fetchingNotifications: false,
        notifications: action.payload.notifications,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    case 'SET_UNREAD_NOTIFICATIONS':
      return { ...state, error: null, loading: false, unreadNotifications: action.payload };
    default:
      return state;
  }
};

const requestDispatchScenarios: {
  [key in FetchType]: 'REQUEST_MORE_NOTIFICATIONS' | 'REQUEST_NOTIFICATIONS';
} = {
  getMore: 'REQUEST_MORE_NOTIFICATIONS',
  initial: 'REQUEST_NOTIFICATIONS',
};

const successDispatchScenarios: {
  [key in FetchType]: 'REQUEST_MORE_NOTIFICATIONS_SUCCESS' | 'REQUEST_NOTIFICATIONS_SUCCESS';
} = {
  getMore: 'REQUEST_MORE_NOTIFICATIONS_SUCCESS',
  initial: 'REQUEST_NOTIFICATIONS_SUCCESS',
};

export const NotificationProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const checkForUnreadNotifications = async (): Promise<void> => {
    dispatch({ type: 'REQUEST' });
    try {
      const response = await getUnreadNotifications();
      dispatch({
        type: 'SET_UNREAD_NOTIFICATIONS',
        payload: response.data.data.attributes.unreadNotifications,
      });
    } catch (e) {
      dispatch({ type: 'FAIL', payload: extractError(e) });
    }
  };

  const fetchNotifications = async (type: FetchType) => {
    dispatch({ type: requestDispatchScenarios[type] });
    try {
      const page = type === 'initial' ? '1' : (state.currentPage + 1).toString();
      const { data, headers } = await getNotifications({ page });

      dispatch({
        type: successDispatchScenarios[type],
        payload: {
          notifications: data.data,
          currentPage: parseInt(headers['current-page'], 10),
          totalPages: parseInt(headers['total-pages'], 10),
        },
      });
    } catch (e) {
      dispatch({ type: 'FAIL', payload: extractError(e) });
    }
  };

  return (
    <NotificationContext.Provider
      value={{ ...state, checkForUnreadNotifications, fetchNotifications }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
