import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = {
  color?: string;
  size?: number;
};

export const BellIcon: FC<Props> = ({ size = 18, color = colors.black }) => {
  return (
    <svg viewBox="0 0 18 18" width={size} height={size}>
      <g transform="matrix(0.75,0,0,0.75,0,0)">
        <path
          d="M10,21.75a2.087,2.087,0,0,0,4.005,0"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M12 3L12 0.75"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M12,3a7.5,7.5,0,0,1,7.5,7.5c0,7.046,1.5,8.25,1.5,8.25H3s1.5-1.916,1.5-8.25A7.5,7.5,0,0,1,12,3Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
