import React, { FC, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Button } from '../../../components/Button/Button';
import { FormikInput } from '../../../components/FormikInput/FormikInput';
import { FormikDropdown } from '../../../components/FormikDropdown/FormikDropdown';
import { FormikTextarea } from '../../../components/FormikTextarea/FormikTextarea';
import './ContactDispatch.scss';
import PHONE from '../../../assets/images/phone.png';

type Props = {
  onSubmit: (values: FormValues, actions: FormikHelpers<FormValues>) => void;
  loading?: boolean;
  error?: boolean;
};

export type FormValues = {
  routeId: string;
  note: string;
};

const initialValues: FormValues = {
  routeId: '',
  note: '',
};

const styles = {
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: 343,
    height: 223,
    backgroundColor: '#F9F9F8',
    borderRadius: 20,
    marginTop: 20,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};

const renderChangeStatus = () => (
  <div style={styles.container}>
    <div className="rounded-lg flex items-center justify-center p-8 bg-brand-light-gray">
      <img src={PHONE} alt="phone" width={100} />
    </div>
    <p className="contactText" style={{ textAlign: 'center' }}>
      To contact customer service, please call the number below:
    </p>
    <div style={styles.buttonContainer}>
      <button className="callButton" type="button">
        1-855-270-7900
      </button>
    </div>
  </div>
);

const reasonOptions = ['General Feedback', 'Change my Delivery Status'];

export const ContactDispatch: FC<Props> = ({ onSubmit, loading, error }) => {
  const [isGeneral, setIsGeneral] = useState(true);
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <div style={{ marginTop: 30, marginBottom: 30, paddingBottom: 20, display: 'flex' }}>
        <Form>
          <div className="flex flex-col">
            <span className="text-sm mb-1 labelTitle">
              Reason<span className="required">*</span>
            </span>
            <FormikDropdown
              id="options"
              options={reasonOptions}
              onChange={() => setIsGeneral(!isGeneral)}
            />

            {isGeneral && (
              <div>
                <span className="text-sm mb-1 labelTitle">Route ID</span>
                <FormikInput id="routeId" />
                <span className="text-sm mb-1 labelTitle">
                  Feedback<span className="required">*</span>
                </span>
                <FormikTextarea id="note" />
                {error && <p className="text-sm mb-1 errorText">Please provide feedback</p>}
                {!loading ? (
                  <div className="inline-block">
                    <Button label="SUBMIT" type="submit" />
                  </div>
                ) : (
                  <div className="loader" />
                )}
              </div>
            )}

            {!isGeneral && renderChangeStatus()}
          </div>
        </Form>
      </div>
    </Formik>
  );
};
