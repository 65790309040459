import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ProfileForm } from './ProfileForm';
import { PasswordForm } from './PasswordForm';
import { MainLayout } from '../../components/MainLayout/MainLayout';

type Props = RouteComponentProps;

export const Profile: FC<Props> = ({ path }) => {
  return (
    <MainLayout path={path}>
      <div className="flex flex-col lg:flex-row px-4 lg:px-0">
        <div className="w-64 p-t-12 pb-8 mr-8">
          <ProfileForm />
        </div>
        <div className="w-64 p-t-12">
          <PasswordForm />
        </div>
      </div>
    </MainLayout>
  );
};
