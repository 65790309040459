export type DeliveryDictionary = NormalizedCollection<Delivery>;
export type DriverDictionary = NormalizedCollection<Driver>;

export type FetchType = 'initial' | 'getMore';

export type RoutesAction =
  | { type: 'CLEAR_ACTIVE_ROUTE' }
  | {
      type: 'REQUEST_MORE_ROUTES_SUCCESS';
      payload: {
        routes: Route[];
        currentPage: number;
        totalPages: number;
      };
    }
  | { type: 'REQUEST_MORE_ROUTES' }
  | {
      type: 'REQUEST_ROUTES_SUCCESS';
      payload: {
        routes: Route[];
        currentPage: number;
        totalPages: number;
      };
    }
  | { type: 'REQUEST_ROUTES' }
  | {
      type: 'SET_ACTIVE_ROUTE';
      payload: {
        route: Route;
        deliveryDictionary: NormalizedCollection<Delivery>;
        driverDictionary: NormalizedCollection<Driver>;
      };
    }
  | { type: 'SET_ACTIVE_ROUTE_ID'; payload: string }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SINGLE_ROUTE_REQUEST' };

export interface RoutesHookReturn extends RoutesState {
  clearActiveRoute: () => void;
  getRoutes: (type: FetchType) => Promise<void>;
  setActiveRouteId: (routeId: string) => void;
}

export interface RoutesState {
  activeRoute: Route;
  activeRouteId: string | null;
  deliveriesLoading: boolean;
  deliveryDictionary: NormalizedCollection<Delivery>;
  driverDictionary: NormalizedCollection<Driver>;
  error: string | null;
  fetchingMoreRoutes: boolean;
  loading: boolean;
  routes: Route[];
  // Pagination
  currentPage: number;
  totalPages: number;
}

export const NullDriver: Driver = {
  id: '',
  type: 'driver',
  attributes: {
    createdAt: '',
    firstName: '',
    image: null,
    lastName: '',
    updatedAt: '',
  },
};

export const NullRoute: Route = {
  id: '',
  type: 'route',
  attributes: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    completed: false,
    country: '',
    createdAt: '',
    minutesLate: 0,
    onTime: false,
    zip: '',
    updatedAt: '',
  },
  relationships: {
    driver: {
      data: { id: '', type: 'driver' },
    },
    deliveries: {
      data: null,
    },
  },
};
