import React, { FC, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormikInput } from '../../components/FormikInput/FormikInput';
import { FormLabel } from '../../components/FormLabel/FormLabel';
import { Button } from '../../components/Button/Button';
import { useResetPassword } from '../../hooks/useResetPassword/useResetPassword';

type FormValues = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
};

const initialValues: FormValues = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
};

const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('Please enter your current password'),
  password: yup.string().required('Please enter a password'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password')], 'Passwords do not match'),
});

export const PasswordForm: FC = () => {
  const { changePasswordForAuthenticatedUser, loading } = useResetPassword();
  const [editing, setEditing] = useState(false);

  const handleCancelEditClick = () => setEditing(false);
  const handleEditClick = () => setEditing(true);

  const handleSubmit = (values: FormValues) => {
    const { currentPassword, password, passwordConfirmation } = values;
    changePasswordForAuthenticatedUser(
      currentPassword,
      password,
      passwordConfirmation,
      (): void => {
        setEditing(false);
      },
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        {!editing && (
          <>
            <FormLabel title="Password" />
            <span className="block mb-4">*******</span>
            <Button label="UPDATE PASSWORD" onClick={handleEditClick} />
          </>
        )}

        {editing && (
          <>
            <FormLabel title="Current Password" required />
            <FormikInput id="currentPassword" password />

            <FormLabel title="Password" required />
            <FormikInput id="password" password />

            <div className="mb-6">
              <FormLabel title="Password Confirmation" required={editing} />
              <FormikInput id="passwordConfirmation" password />
            </div>

            <div className="inline-block mr-3">
              <Button label={loading ? 'UPDATING' : 'UPDATE'} type="submit" disabled={loading} />
            </div>
            <Button label="CANCEL" type="button" onClick={handleCancelEditClick} theme="light" />
          </>
        )}
      </Form>
    </Formik>
  );
};
