import React, { FC } from 'react';
import { Link } from '@reach/router';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { NewPasswordFormValues } from './ResetPassword.types';
import { Button } from '../../components/Button/Button';
import { FormikInput } from '../../components/FormikInput/FormikInput';
import { useResetPassword } from '../../hooks/useResetPassword/useResetPassword';

type Props = {
  error: string | null;
  loading: boolean;
  onSubmit: (password: string, passwordConfirmation: string, resetToken: string) => void;
  resetToken: string;
  message: string | null;
};

const initialValues: NewPasswordFormValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = yup.object().shape({
  password: yup.string().required('Please enter your new password'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords do not match')
    .required('Please re-enter your new password'),
});

export const NewPasswordForm: FC<Props> = ({ error, loading, message, onSubmit, resetToken }) => {
  const { RESET_PASSWORD_SUCCESS_MESSAGE } = useResetPassword();

  const passwordUpdated = message === RESET_PASSWORD_SUCCESS_MESSAGE;

  const handleGoBack = () => {};

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values.password, values.passwordConfirmation, resetToken)}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="flex flex-col">
          <span className="text-sm mb-1">Password</span>
          <FormikInput id="password" password />

          <span className="text-sm mb-1">Password Confirmation</span>
          <div className="mb-8">
            <FormikInput id="passwordConfirmation" password />
          </div>

          <div className="inline-block mb-4">
            <Button
              label="CHANGE PASSWORD"
              type="submit"
              disabled={loading}
              loading={loading}
              onClick={passwordUpdated ? handleGoBack : undefined}
            />
          </div>

          {!loading && passwordUpdated && !error && (
            <span className="text-brand-green mb-8">
              Password successfully updated!{' '}
              <Link className="underline" to="/">
                Click here
              </Link>{' '}
              to log in with your new password.
            </span>
          )}

          {!!error && <span className="text-brand-error mb-8">{error}</span>}
        </div>
      </Form>
    </Formik>
  );
};
