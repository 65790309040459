import React, { FC } from 'react';
import { BellIcon } from '../../assets/icons/BellIcon';
import { colors } from '../../styles/colors';

export const EmptyState: FC = () => {
  return (
    <div className="w-full h-full flex items-center flex-col">
      <div className="mb-4 mt-16">
        <BellIcon size={60} color={colors.inactiveGray} />
      </div>
      <span className="mb-16 text-brand-inactive-gray">No notifications</span>
    </div>
  );
};
