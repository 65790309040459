import React, { FC, useContext, useState, useEffect } from 'react';
import { OverlayHeader } from './OverlayHeader';
import { StarRating } from './StarRating';
import { Button } from '../Button/Button';
import { FormLabel } from '../FormLabel/FormLabel';
import { OverlayContext } from '../../context/OverlayContext/OverlayContext';
import { postDriverFeeback } from '../../services/feedbackService/feedbackService';
import { ProfileIcon } from '../../assets/icons';

export const DriverFeedbackForm: FC = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [formattedImg, setFormattedImg] = useState('');
  const { activeDriver, deactivateOverlay } = useContext(OverlayContext);
  const {
    id: driverId,
    attributes: { firstName, lastName, image },
  } = activeDriver;

  const formatImg = () => {
    const base64String = btoa(String.fromCharCode(...new Uint8Array(JSON.parse(image))));
    setFormattedImg(base64String);
  };

  useEffect(() => {
    formatImg();
  }, []);

  const handleSubmit = async (): Promise<void> => {
    try {
      await postDriverFeeback({ driverId, rating, feedback });
      deactivateOverlay();
    } catch (e) {
      //
    }
  };

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(event.target.value);
  };

  return (
    <div className="flex flex-col">
      <OverlayHeader title="Submit Driver Feedback" />

      {!image && (
        <div className="mb-4">
          <ProfileIcon size={66} />
        </div>
      )}

      {!!image && (
        <img
          className="h-16 w-16 rounded-full mb-4"
          src={`data:image/png;base64,${formattedImg}`}
          alt="avatar"
        />
      )}

      <span className="text-xl font-bold mb-4">{`${firstName ?? ''} ${lastName ?? ''}`}</span>

      <FormLabel title="Add a star rating:" />
      <StarRating rating={rating} setRating={setRating} />

      <FormLabel title="Comments:" containerClassName="mt-8" />
      <textarea
        value={feedback}
        name="driverRating"
        id=""
        cols={30}
        rows={10}
        onChange={handleTextareaChange}
        className="border border-brand-border-gray rounded-lg resize-none mb-8 focus:shadow-outline focus:outline-none px-2 py-2"
      />
      <div className="flex justify-start">
        <Button label="SUBMIT" onClick={handleSubmit} />
      </div>
    </div>
  );
};
