import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = {
  size?: number;
};

export const CloseIcon: FC<Props> = ({ size = 25 }) => {
  const color = colors.black;

  return (
    <svg viewBox="0 0 25 25" width={size} height={size}>
      <g transform="matrix(1.0416666666666667,0,0,1.0416666666666667,0,0)">
        <path
          d="M0.75 23.249L23.25 0.749"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M23.25 23.249L0.75 0.749"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
