import React, { FC, useContext, useState } from 'react';
import { Link } from '@reach/router';
import { Transition } from '@tailwindui/react';
import paceLogo from '../../assets/images/paceLogo.png';
import { MenuIcon } from '../../assets/icons';
import { AuthContext } from '../../context/AuthContext/AuthContext';

type MobileHeaderProps = { path: string | undefined };
type MenuItemProps = { label: string; path: string | undefined };

const menuItems = [
  { label: 'Dashboard' },
  { label: 'Routes' },
  { label: 'Support' },
  { label: 'News' },
  { label: 'Users' },
  { label: 'Settings' },
  { label: 'Profile' },
  { label: 'Log out' },
];

export const MenuItem: FC<MenuItemProps> = ({ label, path }) => {
  const { logout } = useContext(AuthContext);
  const destination = `/${label.toLowerCase()}`;

  if (label === 'Log out') {
    return (
      <button
        type="button"
        className="flex w-full px-8 py-2 text-lg text-brand-black hover:bg-gray-100 transition ease-in-out duration-150"
        onClick={() => {
          logout();
        }}
      >
        <div>{label}</div>
      </button>
    );
  }

  if (path === destination) {
    return (
      <div className="block px-8 py-2 text-lg transition ease-in-out duration-150} text-brand-inactive-gray">
        {label}
      </div>
    );
  }

  return (
    <Link to={destination}>
      <div className="block px-8 py-2 text-lg text-brand-black hover:bg-gray-100 transition ease-in-out duration-150">
        {label}
      </div>
    </Link>
  );
};

export const MobileHeader: FC<MobileHeaderProps> = ({ path }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className="h-16 w-100 px-4 flex justify-between items-center h-100 lg:hidden hover:bg-t">
        <img src={paceLogo} alt="Pace logo" className="object-contain h-5 w-full object-left" />
        <div>
          <button
            type="button"
            id="main-menu"
            aria-label="Main menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MenuIcon />
          </button>
        </div>
      </div>

      <Transition
        show={menuOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {(ref) => (
          <div ref={ref} className="absolute w-100 bg-white w-screen shadow-lg lg:hidden z-10">
            <div className="">
              {menuItems.map((item, index) => (
                <MenuItem label={item.label} path={path} key={index.toString()} />
              ))}
            </div>
          </div>
        )}
      </Transition>
    </>
  );
};
