import React, { FC, useState } from 'react';
import { Formik, Form } from 'formik';
import { FormikInput } from '../../../components/FormikInput/FormikInput';
import { RadioOption } from '../../../components/RadioOption/RadioOption';

type FormValues = {
  fleetId: string;
  address: string;
  cityStateZip: string;
};

const initialValues: FormValues = {
  fleetId: '',
  address: '',
  cityStateZip: '',
};

export const Routes: FC = () => {
  const [isFleetId, setIsFleetId] = useState(true);
  const handleSubmit = (values: FormValues) => {
    // TODO: submit form to backend
  };
  return (
    <div>
      <h1 className="title">What is your origination point?</h1>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <RadioOption
            title="Fleet ID"
            checked={isFleetId}
            value="fleetId"
            onChange={() => setIsFleetId(!isFleetId)}
          />
          <div style={{ maxWidth: 300, marginLeft: 25 }}>
            <FormikInput id="fleetId" placeholder="Fleet ID" disabled={!isFleetId} />
          </div>

          <RadioOption
            title="Address"
            checked={!isFleetId}
            value="address"
            onChange={() => setIsFleetId(!isFleetId)}
          />
          <div style={{ maxWidth: 300, marginLeft: 25 }}>
            <FormikInput id="address" placeholder="Street Address" disabled={isFleetId} />
            <FormikInput id="cityStateZip" placeholder="City, State, Zip" disabled={isFleetId} />
          </div>
          <button type="submit" className="saveButton" style={{ outline: 'none' }}>
            SAVE
          </button>
        </Form>
      </Formik>
    </div>
  );
};
