import React, { FC } from 'react';

type Props = {
  title: string;
  required?: boolean;
  containerClassName?: string;
};

export const FormLabel: FC<Props> = ({ title, containerClassName, required = false }) => {
  return (
    <div className={`mb-1 ${containerClassName}`}>
      <span className="labelTitle">{title}</span>
      {required && <span className="required"> *</span>}
    </div>
  );
};
