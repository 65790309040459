import React, { FC, createContext, useReducer, useEffect } from 'react';
import normalize from 'json-api-normalizer';
import { AuthAction, Provider, NullCustomer, NullSession, NullUser } from './AuthContext.types';
import { deleteSession, postLogin } from '../../services/authService/authService';
import {
  updateEmailNotifications,
  updateHomescreen,
  updateTextNotifications,
} from '../../services/settingsService/settingsService';
import { EE } from '../../services/eventEmitter';
import { patchUser, UserFormValues } from '../../services/userService/userService';
import { LOGOUT_EVENT } from '../../utils/constants';
import { extractError } from '../../utils/errorUtils';
import { clearLocalStore, fetchSession, storeSession } from '../../utils/storageUtils';

const INITIAL_STATE: AuthState = {
  associatedCompany: NullCustomer,
  error: null,
  loading: false,
  userInfo: NullUser,
  session: NullSession,
};

const reducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case 'LOGIN_START':
      return { ...state, loading: true };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loading: false,
        session: action.payload.session,
        userInfo: action.payload.user,
        associatedCompany: action.payload.associatedCompany,
      };
    case 'LOG_OUT':
      return INITIAL_STATE;
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'REHYDRATE_REDUCER':
      return action.payload;
    case 'UPDATE_SETTINGS_START':
      return { ...state, loading: true };
    case 'UPDATE_SETTINGS_SUCCESS':
      return {
        ...state,
        loading: false,
        userInfo: {
          ...state.userInfo,
          attributes: { ...state.userInfo.attributes, settings: action.payload },
        },
      };
    case 'UPDATE_USER':
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          attributes: {
            ...state.userInfo.attributes,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export const AuthContext = createContext<Provider>({} as Provider);

const getInitialState = () => {
  const session = fetchSession();
  if (session) return session;
  return INITIAL_STATE;
};

export const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  useEffect((): (() => void) => {
    EE.removeAllListeners();
    EE.on(LOGOUT_EVENT, () => logout(() => {}, false));
    return () => {
      EE.removeAllListeners();
    };
  }, []);

  // saves the state to localStorage on every change
  useEffect(() => {
    storeSession(state);
  }, [state]);

  const login = async (
    values: { email: string; password: string },
    successCallback?: (destination: string) => void,
  ) => {
    try {
      dispatch({ type: 'LOGIN_START' });
      const response = await postLogin(values);

      const { customer: customers } = normalize(response.data);
      const user = response.data.data;
      const customerId = user.relationships.customer?.data?.id ?? '';
      const associatedCompany = customerId
        ? customers[user.relationships.customer.data.id]
        : NullCustomer;
      const token = response.headers['access-token'];
      const tokenType = response.headers['token-type'];
      const { client, expiry } = response.headers;
      const { email: uid } = user.attributes;

      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: { associatedCompany, user, session: { token, tokenType, uid, client, expiry } },
      });

      if (successCallback) successCallback(user.attributes.settings.homeScreen);
    } catch (e) {
      dispatch({ type: 'SET_ERROR', payload: extractError(e) });
    }
  };

  const logout = async (successCallback?: () => void, deleteSessionOnServer = true) => {
    try {
      // the following is skipped if logout() is called from the event listener in the
      // useEffect() at the top of the component. This avoids an infinite loop.
      if (deleteSessionOnServer) await deleteSession();
      clearLocalStore();
      dispatch({ type: 'LOG_OUT' });
      if (successCallback) successCallback();
    } catch {
      clearLocalStore();
      dispatch({ type: 'LOG_OUT' });
      if (successCallback) successCallback();
    }
  };

  const changeHomescreen = async (screen: InitialHomescreen) => {
    try {
      const response = await updateHomescreen(screen);
      dispatch({
        type: 'UPDATE_SETTINGS_SUCCESS',
        payload: response.data.data.attributes.settings,
      });
    } catch (error) {
      //
    }
  };

  const changeEmailNotifications = async (value: boolean) => {
    try {
      const response = await updateEmailNotifications(value);
      dispatch({
        type: 'UPDATE_SETTINGS_SUCCESS',
        payload: response.data.data.attributes.settings,
      });
    } catch {
      //
    }
  };

  const changeTextNotifications = async (value: boolean) => {
    try {
      const response = await updateTextNotifications(value);
      dispatch({
        type: 'UPDATE_SETTINGS_SUCCESS',
        payload: response.data.data.attributes.settings,
      });
    } catch {
      //
    }
  };

  const updateUser = async (
    userId: string,
    values: UserFormValues,
    successCallback?: () => void,
  ) => {
    try {
      const response = await patchUser(userId, values);
      const { email, firstName, lastName, phone } = response.data.data.attributes;
      dispatch({ type: 'UPDATE_USER', payload: { email, firstName, lastName, phone } });
      if (successCallback) successCallback();
    } catch (e) {
      dispatch({ type: 'SET_ERROR', payload: 'There was an error updating your profile.' });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        changeEmailNotifications,
        changeHomescreen,
        changeTextNotifications,
        login,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
