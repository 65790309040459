import React, { FC, useEffect, useContext } from 'react';
import { Table } from 'antd';
import startCase from 'lodash/startCase';
import upperFirst from 'lodash/upperFirst';
import './UserTable.scss';
import { OverlayContext } from '../../context/OverlayContext/OverlayContext';
import { useUsers } from '../../hooks/useUsers/useUsers';
import { byLastName, getCompanyName } from '../../utils/userUtils';

export type AntdRow = {
  id: string;
  key: string;
  name: string;
  email: string;
  role: string;
  status: string;
  company: string;
};

const formatRole = (role: User['attributes']['role']) => startCase(role);

export const UserTable: FC = () => {
  const { openDeleteUserConfirmation } = useContext(OverlayContext);
  const {
    currentPage,
    customerDictionary,
    fetchUsers,
    loading,
    totalNumberOfUsers,
    users,
  } = useUsers();

  useEffect(() => {
    fetchUsers();
  }, []);

  const formatTableData = (user: User): AntdRow => ({
    id: user.id,
    key: user.id,
    name: `${user.attributes.firstName} ${user.attributes.lastName}`,
    email: user.attributes.email,
    role: formatRole(user.attributes.role),
    status: upperFirst(user.attributes.status),
    company: getCompanyName(user, customerDictionary),
  });

  const sortedUsers = users.sort(byLastName);
  const tableData: AntdRow[] = sortedUsers.length > 0 ? sortedUsers.map(formatTableData) : [];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: AntdRow) => (
        <button
          className="deactivateButton"
          type="button"
          onClick={() => openDeleteUserConfirmation(record.id, () => fetchUsers())}
        >
          Delete
        </button>
      ),
    },
  ];

  const handlePageChange = (page: number) => fetchUsers({ page });

  const itemRender = (
    _: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement,
  ) => {
    if (type === 'next' || type === 'prev') return null;
    return originalElement;
  };

  return (
    <div className="w-full shadow-lg rounded-lg">
      <div className="w-full overflow-hidden rounded-lg">
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            current: currentPage,
            defaultPageSize: 20,
            onChange: handlePageChange,
            position: ['bottomCenter'],
            showSizeChanger: false,
            total: totalNumberOfUsers,
            itemRender,
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};
