import React, { FC } from 'react';
import { ErrorMessage, Field } from 'formik';

type Props = {
  id: string;
};

export const FormikTextarea: FC<Props> = ({ id }) => {
  return (
    <div className="w-full mb-4">
      <Field
        id={id}
        name={id}
        as="textarea"
        className="rounded-lg border border-solid border-brand-border-gray h-20 focus:shadow-outline focus:outline-none px-2 py-2 block w-full resize-none"
      />

      <ErrorMessage name={id} component="span" className="text-sm text-brand-error" />
    </div>
  );
};
