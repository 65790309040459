import axios, { AxiosRequestConfig } from 'axios';
import { handleErrorResponse, handleSuccessResponse } from './responseHandlers/responseHandlers';
import { responseTransformer } from './transformers/responseTransformer';
import { requestTransformer } from './transformers/requestTransformer';
import { fetchSession } from '../utils/storageUtils';

const getStoredHeaderValues = (config: AxiosRequestConfig) => {
  if (!config.headers.access_token || !config.headers.uid) {
    const authState = fetchSession();

    config.headers.access_token = authState ? authState.session.token : ''; // eslint-disable-line
    config.headers.client = authState ? authState.session.client : ''; // eslint-disable-line
    config.headers.token_type = authState ? authState.session.tokenType : ''; // eslint-disable-line
    config.headers.expiry = authState ? authState.session.expiry : ''; // eslint-disable-line
    config.headers.uid = authState ? authState.session.uid : ''; // eslint-disable-line
  }

  return config;
};

export const baseService = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  transformRequest: [requestTransformer],
  transformResponse: [responseTransformer],
});

baseService.interceptors.request.use(getStoredHeaderValues);

/**
 * From the axios docs: Any status code that lies within the range of 2xx [will] cause the
 * handleSuccessResponse function to trigger. Any status outside of that range will cause
 * the handleErrorResponse function to trigger.
 */
baseService.interceptors.response.use(handleSuccessResponse, handleErrorResponse);
