import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = { active?: boolean; size?: number };

export const DashboardIcon: FC<Props> = ({ active = false, size = 32 }) => {
  const orange = active ? colors.orange : colors.inactiveGray;
  const black = active ? colors.black : colors.inactiveGray;
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <path
        d="M12 20.38V20C12 18.9391 12.4214 17.9217 13.1716 17.1716C13.9217 16.4214 14.9391 16 16 16C17.0609 16 18.0783 16.4214 18.8284 17.1716C19.5786 17.9217 20 18.9391 20 20V20.38"
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0029 7.00012L16.0029 16.0001"
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50244 10C8.40355 10 8.30688 10.0293 8.22466 10.0843C8.14243 10.1392 8.07835 10.2173 8.0405 10.3087C8.00266 10.4 7.99276 10.5006 8.01205 10.5975C8.03134 10.6945 8.07896 10.7836 8.14889 10.8536C8.21881 10.9235 8.30791 10.9711 8.4049 10.9904C8.50189 11.0097 8.60242 10.9998 8.69378 10.9619C8.78515 10.9241 8.86324 10.86 8.91818 10.7778C8.97312 10.6956 9.00244 10.5989 9.00244 10.5C9.00244 10.3674 8.94976 10.2402 8.856 10.1464C8.76223 10.0527 8.63505 10 8.50244 10Z"
        fill={orange}
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5024 15C25.4036 15 25.3069 15.0293 25.2247 15.0843C25.1424 15.1392 25.0783 15.2173 25.0405 15.3087C25.0027 15.4 24.9928 15.5006 25.012 15.5975C25.0313 15.6945 25.079 15.7836 25.1489 15.8536C25.2188 15.9235 25.3079 15.9711 25.4049 15.9904C25.5019 16.0097 25.6024 15.9998 25.6938 15.9619C25.7851 15.9241 25.8632 15.86 25.9182 15.7778C25.9731 15.6956 26.0024 15.5989 26.0024 15.5C26.0024 15.3674 25.9498 15.2402 25.856 15.1464C25.7622 15.0527 25.6351 15 25.5024 15V15Z"
        fill={orange}
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5027 10C23.4037 9.99948 23.3067 10.0284 23.2242 10.083C23.1416 10.1376 23.0771 10.2155 23.0388 10.3068C23.0006 10.3981 22.9903 10.4987 23.0093 10.5959C23.0283 10.6931 23.0757 10.7824 23.1455 10.8526C23.2153 10.9228 23.3044 10.9707 23.4015 10.9902C23.4985 11.0097 23.5992 11 23.6907 10.9622C23.7822 10.9244 23.8605 10.8603 23.9155 10.7781C23.9706 10.6958 24 10.599 24 10.5C24 10.3674 23.9473 10.2402 23.8536 10.1465C23.7598 10.0527 23.6326 10 23.5 10"
        fill={orange}
      />
      <path
        d="M23.5027 10C23.4037 9.99948 23.3067 10.0284 23.2242 10.083C23.1416 10.1376 23.0771 10.2155 23.0388 10.3068C23.0006 10.3981 22.9903 10.4987 23.0093 10.5959C23.0283 10.6931 23.0757 10.7824 23.1455 10.8526C23.2153 10.9228 23.3044 10.9707 23.4015 10.9902C23.4985 11.0097 23.5992 11 23.6907 10.9622C23.7822 10.9244 23.8605 10.8603 23.9155 10.7781C23.9706 10.6958 24 10.599 24 10.5C24 10.3674 23.9473 10.2402 23.8536 10.1465C23.7598 10.0527 23.6326 10 23.5 10"
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5024 6.00012C12.4036 6.00012 12.3069 6.02945 12.2247 6.08439C12.1424 6.13933 12.0783 6.21742 12.0405 6.30878C12.0027 6.40014 11.9928 6.50068 12.012 6.59767C12.0313 6.69466 12.079 6.78375 12.1489 6.85368C12.2188 6.9236 12.3079 6.97122 12.4049 6.99051C12.5019 7.00981 12.6024 6.99991 12.6938 6.96206C12.7851 6.92422 12.8632 6.86013 12.9182 6.77791C12.9731 6.69568 13.0024 6.59901 13.0024 6.50012C13.0024 6.36751 12.9498 6.24034 12.856 6.14657C12.7622 6.0528 12.635 6.00012 12.5024 6.00012V6.00012Z"
        fill={orange}
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5746 24.1802C24.9439 21.4665 20.5327 20.0001 16 20.0001C11.4672 20.0001 7.056 21.4665 3.42529 24.1802"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 16C1 19.9782 2.58035 23.7936 5.3934 26.6066C8.20644 29.4196 12.0218 31 16 31C19.9782 31 23.7936 29.4196 26.6066 26.6066C29.4196 23.7936 31 19.9782 31 16C31 12.0218 29.4196 8.20644 26.6066 5.3934C23.7936 2.58035 19.9782 1 16 1C12.0218 1 8.20644 2.58035 5.3934 5.3934C2.58035 8.20644 1 12.0218 1 16V16Z"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50244 15C6.40355 15 6.30688 15.0293 6.22466 15.0843C6.14243 15.1392 6.07835 15.2173 6.0405 15.3087C6.00266 15.4 5.99276 15.5006 6.01205 15.5975C6.03134 15.6945 6.07896 15.7836 6.14889 15.8536C6.21881 15.9235 6.30791 15.9711 6.4049 15.9904C6.50189 16.0097 6.60242 15.9998 6.69378 15.9619C6.78515 15.9241 6.86324 15.86 6.91818 15.7778C6.97312 15.6956 7.00244 15.5989 7.00244 15.5C7.00244 15.3674 6.94976 15.2402 6.856 15.1464C6.76223 15.0527 6.63505 15 6.50244 15Z"
        fill={orange}
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
