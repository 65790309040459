export const checkForSession = (): boolean => {
  const email = localStorage.getItem('email');
  const token = localStorage.getItem('token');
  return !!email && !!token;
};

export const storeSession = (authState: AuthState): void => {
  localStorage.setItem('authState', JSON.stringify(authState));
};

export const fetchSession = (): AuthState | null => {
  const storedValue = localStorage.getItem('authState');
  if (!storedValue) return null;
  return JSON.parse(storedValue);
};

export const clearLocalStore = (): void => {
  localStorage.clear();
};
