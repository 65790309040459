/* eslint-disable react/jsx-no-target-blank */
import React, { FC, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import './EmptyState.scss';
import { EmptyState } from './EmptyState';

type Props = RouteComponentProps;

export const Dashboard: FC<Props> = ({ path }) => {
  const { associatedCompany } = useContext(AuthContext);
  const dashboardUrl = associatedCompany?.attributes.dashboardUrl ?? '';

  return (
    <div className="h-full w-full">
      <MainLayout path={path}>
        {/* {dashboardUrl ? (
          <iframe
            src={dashboardUrl}
            title="Microsoft Power BI"
            style={{ width: '100%', height: '100vh' }}
          />
        ) : (
          <EmptyState />
        )} */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <button className="dashBtn" style={{ outline: 'none' }} type="button">
            <a href="https://04940.cxtsoftware.net/rapidship/#/home" target="_blank">
              View CXT Dashboard
            </a>
          </button>
        </div>
      </MainLayout>
    </div>
  );
};
