import React, { FC, useContext } from 'react';
import { Link, RouteComponentProps, navigate, Redirect } from '@reach/router';
import { FormValues, LoginForm } from './LoginForm';
import paceLogo from '../../assets/images/paceLogo.png';
import truckPhoto from '../../assets/images/truckPhoto.png';
import { AuthContext } from '../../context/AuthContext/AuthContext';

type Props = RouteComponentProps;

export const Login: FC<Props> = () => {
  const { login, session, userInfo } = useContext(AuthContext);
  const activeSession = !!session.uid && !!session.token;

  const signUserIn = (values: FormValues) => {
    login(values, (destination: string) => navigate(`/${destination}`));
  };

  if (activeSession) {
    return <Redirect to={`/${userInfo.attributes.settings.homeScreen}`} noThrow />;
  }

  return (
    <div className="flex px-0 pt-16 lg:pt-24 xl:pt-32 lg:px-48 xl:px-64 bg-brand-light-gray">
      <div
        className="rounded-lg flex flex-row flex-1 overflow-hidden shadow-lg mb-32"
        style={{ maxHeight: 1000 }}
      >
        <div className="flex flex-col flex-1 h-full bg-white px-10 md:px-32 lg:px-20 lg:mb-24 xl:mb-32 pt-20 pb-8">
          <div className="flex-1">
            <img src={paceLogo} alt="pace logo" className="h-16 mb-8" />
            <h1 className="text-lg font-bold mb-4">Sign In</h1>
            <LoginForm onSubmit={signUserIn} />
          </div>
          <div>
            <span className="text-sm">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Don't have an account?{' '}
              <Link to="/sign-up" className="text-brand-orange font-bold">
                Sign up now.
              </Link>
            </span>
          </div>
        </div>
        <div className="hidden lg:flex lg:flex-1">
          <img src={truckPhoto} alt="semi-truck" className="object-cover h-full w-full" />
        </div>
      </div>
    </div>
  );
};
