import React, { FC, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DeleteUserModal } from './DeleteUserModal';
import { DriverFeedbackForm } from './DriverFeedbackForm';
import { ContactDispatchForm } from './ContactDispatchForm';
import { InviteUserForm } from './InviteUserForm';
import './UtilityOverlay.scss';
import { CloseIcon } from '../../assets/icons';
import { OverlayContext } from '../../context/OverlayContext/OverlayContext';

export const UtilityOverlay: FC = () => {
  const { deactivateOverlay, overlayActive, overlayView } = useContext(OverlayContext);
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const InviteUserModal: FC = () => {
    return (
      <div className="absolute top-0 left-0 h-screen w-screen flex flex-1 justify-center items-center bg-transparent">
        <div className={`inviteModal ${isDesktop ? 'constrainedWidth' : ''}`}>
          <InviteUserForm />
        </div>
      </div>
    );
  };

  const Sidebar: FC = () => {
    return (
      <div
        className={`absolute right-0 top-0 py-8 px-8 bg-white opacity-100 z-20 lg:rounded-l-lg min-h-full w-full lg:w-1/3 ${
          !overlayActive && 'hidden'
        }`}
      >
        <div className="mb-4 flex justify-end w-full">
          <button type="button" onClick={() => deactivateOverlay()} style={{ outline: 'none' }}>
            <CloseIcon size={20} />
          </button>
        </div>
        {overlayView === 'rateDriver' && <DriverFeedbackForm />}
        {overlayView === 'contactDispatch' && <ContactDispatchForm />}
      </div>
    );
  };

  if (overlayActive) {
    return (
      <>
        <div
          className={`fixed top-0 left-0 h-full w-full bg-black z-10 opacity-75 ${
            !overlayActive && 'hidden'
          }`}
        />

        {overlayView === 'contactDispatch' || overlayView === 'rateDriver' ? <Sidebar /> : null}
        {overlayView === 'inviteUser' ? <InviteUserModal /> : null}
        {overlayView === 'confirmUserDelete' ? <DeleteUserModal /> : null}
      </>
    );
  }

  return null;
};
