import { UserFormValues } from '../../services/userService/userService';

export type AuthAction =
  | { type: 'LOG_OUT' }
  | { type: 'LOGIN_START' }
  | {
      type: 'LOGIN_SUCCESS';
      payload: { session: Session; user: User; associatedCompany: Customer };
    }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'REHYDRATE_REDUCER'; payload: AuthState }
  | { type: 'UPDATE_SETTINGS_START' }
  | { type: 'UPDATE_SETTINGS_SUCCESS'; payload: User['attributes']['settings'] }
  | {
      type: 'UPDATE_USER';
      payload: Pick<User['attributes'], 'email' | 'firstName' | 'lastName' | 'phone'>;
    };

export interface Provider extends AuthState {
  login: (
    values: { email: string; password: string },
    successCallback?: (destination: string) => void,
  ) => void;
  logout: (successCallback?: () => void) => void;
  changeHomescreen: any;
  changeEmailNotifications: any;
  changeTextNotifications: any;
  updateUser: (userId: string, values: UserFormValues, successCallback?: () => void) => void;
}

export const NullCustomer: Customer = {
  id: '',
  type: 'customer',
  attributes: {
    createdAt: '',
    cxtId: '',
    dashboardUrl: '',
    name: '',
    updatedAt: '',
  },
};

export const NullUser: User = {
  id: '',
  type: 'user',
  attributes: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    createdAt: '',
    updatedAt: '',
    role: 'customer_user',
    status: 'active',
    settings: {
      homeScreen: 'dashboard',
      textNotifications: false,
      emailNotifications: false,
    },
  },
  relationships: {
    customer: { data: { id: '', type: 'customer' } },
  },
};

export const NullSession: Session = {
  client: '',
  token: '',
  tokenType: '',
  expiry: '',
  uid: '',
};
