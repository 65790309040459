import React, { FC } from 'react';
import './CustomerService.scss';
import PHONE from '../../../assets/images/phone.png';

const styles = {
  container: {
    width: 343,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: 243,
    backgroundColor: '#F9F9F8',
    borderRadius: 20,
    marginTop: 20,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};

export const CustomerService: FC = () => {
  return (
    <div style={styles.container}>
      <div style={styles.imgContainer}>
        <img src={PHONE} width={150} alt="phone" />
      </div>
      <p className="contactText" style={{ textAlign: 'center' }}>
        To Contact Customer Service, Please Call the Number Below
      </p>
      <div style={styles.buttonContainer}>
        <button className="callButton" type="button">
          1-866-410-7222
        </button>
      </div>
    </div>
  );
};
