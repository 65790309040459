import React, { FC, useState } from 'react';
import { message } from 'antd';
import { FormikHelpers } from 'formik';
import { RouteComponentProps } from '@reach/router';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import './Support.scss';
import { ContactDispatch, FormValues } from './ContactDispatch/ContactDispatch';
import { CustomerService } from './CustomerService/CustomerService';
import { sendSupport } from '../../services/supportService/supportService';

type Props = RouteComponentProps;

export const Support: FC<Props> = ({ path }) => {
  const [isContactDispatch, setIsContactDispatch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleClick = () => setIsContactDispatch(!isContactDispatch);

  const submitSupport = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);
    try {
      await sendSupport(values);
      message.success('Your feedback was submitted successfully.');
      setIsError(false);
      actions.resetForm();
    } catch (err) {
      setIsError(true);
      message.error('There was an error submitting your feedback.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MainLayout path={path}>
      <div className="px-4 lg:px-0">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button onClick={() => handleClick()} type="button" style={{ outline: 'none' }}>
            <p
              style={{
                color: isContactDispatch ? '#EC5328' : '#9AA4B3',
                fontWeight: 'bold',
                textDecoration: isContactDispatch ? 'underline' : undefined,
              }}
              className="sectionTitle"
            >
              Contact Dispatch
            </p>
          </button>
          <button
            onClick={() => handleClick()}
            type="button"
            style={{ marginLeft: 40, outline: 'none' }}
          >
            <p
              style={{
                color: isContactDispatch ? '#9AA4B3' : '#EC5328',
                fontWeight: 'bold',
                textDecoration: isContactDispatch ? undefined : 'underline',
              }}
              className="sectionTitle"
            >
              Customer Service
            </p>
          </button>
        </div>
        {isContactDispatch ? (
          <ContactDispatch onSubmit={submitSupport} loading={isLoading} error={isError} />
        ) : (
          <CustomerService />
        )}
      </div>
    </MainLayout>
  );
};
