import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import { HOMESCREEN, NOTIFICATIONS } from './SettingsConstants';
import { Homescreen } from './Homescreen/Homescreen';
import { Notifications } from './Notifications/Notifications';
import { Routes } from './Routes/Routes';

type Props = RouteComponentProps;

export const Settings: FC<Props> = ({ path }) => {
  const [currentTab, setCurrentTab] = useState(HOMESCREEN);
  const handleClick = (tabName: string) => setCurrentTab(tabName);
  const renderBody = () => {
    if (currentTab === HOMESCREEN) return <Homescreen />;
    if (currentTab === NOTIFICATIONS) return <Notifications />;
    return <Routes />;
  };
  return (
    <MainLayout path={path}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          maxWidth: 300,
        }}
      >
        <button onClick={() => handleClick(HOMESCREEN)} type="button" style={{ outline: 'none' }}>
          <p
            style={{
              color: currentTab === HOMESCREEN ? '#EC5328' : '#9AA4B3',
              fontSize: 18,
              fontWeight: 'bold',
              textDecoration: currentTab === HOMESCREEN ? 'underline' : undefined,
            }}
            className="sectionTitle"
          >
            Homescreen
          </p>
        </button>
        <button
          onClick={() => handleClick(NOTIFICATIONS)}
          type="button"
          style={{ outline: 'none' }}
        >
          <p
            style={{
              color: currentTab === NOTIFICATIONS ? '#EC5328' : '#9AA4B3',
              fontSize: 18,
              fontWeight: 'bold',
              textDecoration: currentTab === NOTIFICATIONS ? 'underline' : undefined,
            }}
            className="sectionTitle"
          >
            Notifications
          </p>
        </button>
        {/* <button onClick={() => handleClick(ROUTES)} type="button" style={{ outline: 'none' }}>
          <p
            style={{
              color: currentTab === ROUTES ? '#EC5328' : '#9AA4B3',
              fontSize: 18,
              fontWeight: 'bold',
              textDecoration: currentTab === ROUTES ? 'underline' : undefined,
            }}
            className="sectionTitle"
          >
            Routes
          </p>
        </button> */}
      </div>
      {renderBody()}
    </MainLayout>
  );
};
