import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = {
  className?: string;
  color?: string;
  size?: number;
};

export const HollowStar: FC<Props> = ({ className, color = colors.black, size = 25 }) => {
  return (
    <svg viewBox="0 0 25 25" width={size} height={size} className={className}>
      <g transform="matrix(1.0416666666666667,0,0,1.0416666666666667,0,0)">
        <path
          d="M22.816,8.935a1.469,1.469,0,0,0-1.374-.959H15.5L13.372,1.95a1.465,1.465,0,0,0-2.751.018L8.5,7.976H2.558a1.466,1.466,0,0,0-.941,2.589l5.031,4.169L4.537,21.068a1.465,1.465,0,0,0,2.255,1.648L12,18.9l5.207,3.818a1.465,1.465,0,0,0,2.256-1.644l-2.112-6.338,5.042-4.179A1.47,1.47,0,0,0,22.816,8.935Zm-7.267,4.7a1,1,0,0,0-.311,1.086l1.549,4.647a.25.25,0,0,1-.385.281L12.59,16.851a1,1,0,0,0-1.183,0L7.6,19.644a.25.25,0,0,1-.385-.28l1.548-4.647a1,1,0,0,0-.31-1.086L4.575,10.419a.25.25,0,0,1,.159-.443H9.209a1,1,0,0,0,.943-.668L11.764,4.74a.25.25,0,0,1,.472,0l1.611,4.568a1,1,0,0,0,.943.668h4.475a.25.25,0,0,1,.159.443Z"
          fill={color}
          stroke="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0"
        />
      </g>
    </svg>
  );
};
