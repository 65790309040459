import React, { FC, useContext, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import upperFirst from 'lodash/upperFirst';
import { MobileHeader } from './MobileHeader';
import { NotificationButton } from './NotificationButton';
import { ProfileDropdown } from './ProfileDropdown';
import { Sidebar } from './Sidebar';
import { UtilityOverlay } from './UtilityOverlay';
import { NotificationContext } from '../../context/NotificationContext/NotificationContext';
import { OverlayContext } from '../../context/OverlayContext/OverlayContext';
import './MainLayout.scss';

type Props = {
  path: RouteComponentProps['path'];
};

export const MainLayout: FC<Props> = ({ children, path }) => {
  const { overlayActive } = useContext(OverlayContext);
  const { checkForUnreadNotifications } = useContext(NotificationContext);

  useEffect(() => {
    checkForUnreadNotifications();
  }, []);

  const title = upperFirst(path?.replace('/', ''));

  return (
    <>
      {overlayActive ? <UtilityOverlay /> : null}
      <div
        className={`flex flex-row lg:px-12 lg:pt-10 bg-brand-light-gray pb-10 ${
          overlayActive ? 'fixed w-full' : ''
        }`}
      >
        <Sidebar path={path} />

        <div className="flex-1 h-full">
          <MobileHeader path={path} />
          <div className="flex-1 bg-white pb-8 lg:px-10 md:px-5 sm:px-5 lg:rounded-lg shadow-md mb-12 min-h-screen-90">
            <div className="py-2 lg:py-4 mb-2 lg:mb-6 border-b-2 border-brand-light-gray flex flex-row justify-between items-center">
              <h1 className="ml-4 lg:ml-0 text-xl lg:text-xl font-bold">{title}</h1>
              <div className="flex flex-row items-center">
                <div className="hidden lg:block mr-2 cursor-pointer">
                  <NotificationButton />
                </div>
                <ProfileDropdown />
              </div>
            </div>

            {children}
          </div>
        </div>
      </div>
    </>
  );
};
