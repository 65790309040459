import { useReducer } from 'react';
import { getCustomers } from '../../services/customerService/customerService';
import { extractError } from '../../utils/errorUtils';
import { Action, CustomersHookReturn, State } from './useCustomers.types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FAIL':
      return { ...state, error: action.payload };
    case 'FETCH_CUSTOMERS_SUCCESS':
      return { ...state, error: null, loading: false, customers: action.payload };
    case 'REQUEST':
      return { ...state, loading: true, error: null };
    default:
      return state;
  }
};

const initialState = {
  customers: [],
  loading: false,
  error: null,
};

export const useCustomers = (): CustomersHookReturn => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchCustomers = async () => {
    dispatch({ type: 'REQUEST' });
    try {
      const response = await getCustomers();
      dispatch({ type: 'FETCH_CUSTOMERS_SUCCESS', payload: response.data.data });
    } catch (e) {
      dispatch({ type: 'FAIL', payload: extractError(e) });
    }
  };

  return { ...state, fetchCustomers };
};
