export function stripHtml(input: string): string {
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = input;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
}

export function findCoverImg(input: string): string {
  const figureStartIdx = input.indexOf('<figure');
  const figureEndIdx = input.indexOf('</figure');
  const imgString = input.substring(figureStartIdx, figureEndIdx);
  const imgStartIdx = imgString.indexOf('src="');
  const imgEndIdx = imgString.indexOf('alt=');
  const postImg = imgString.substring(imgStartIdx + 5, imgEndIdx - 2);
  return postImg;
}
