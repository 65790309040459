import moment from 'moment';

type FormatName = 'SHORT_DAY_AND_TIME' | 'STANDARD' | 'TIME' | 'ISO' | 'VERBOSE';

const dateFormats = {
  SHORT_DAY_AND_TIME: 'M/DD h:mma',
  STANDARD: 'MMM DD, YYYY',
  TIME: 'h:mma',
  VERBOSE: 'MMM DD, YYYY h:mma',
};

export const formatDate = (date: string | Date, format: FormatName = 'STANDARD'): string => {
  if (format === 'ISO') return moment(date).toISOString();
  return moment(date).format(dateFormats[format]);
};
