import React, { FC, useContext, useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import * as yup from 'yup';
import './MainLayout.scss';
import { OverlayHeader } from './OverlayHeader';
import { Button } from '../Button/Button';
import { FormikDropdown } from '../FormikDropdown/FormikDropdown';
import { FormikInput } from '../FormikInput/FormikInput';
import { FormikTextarea } from '../FormikTextarea/FormikTextarea';
import { FormLabel } from '../FormLabel/FormLabel';
import PHONE from '../../assets/images/phone.png';
import { OverlayContext } from '../../context/OverlayContext/OverlayContext';
import { sendSupport } from '../../services/supportService/supportService';

type View = 'General Feedback' | 'Change Delivery Schedule';

export type FormValues = {
  routeId: string;
  note: string;
};

export const ContactDispatchForm: FC = () => {
  const { activeRouteId, deactivateOverlay } = useContext(OverlayContext);
  const [activeView, setActiveView] = useState<View>('General Feedback');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const reasons = ['General Feedback', 'Change Delivery Schedule'];

  const submitSupport = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ): Promise<void> => {
    setIsLoading(true);
    setIsError(false);
    try {
      await sendSupport(values);
      setIsLoading(false);
      setIsError(false);
      actions.resetForm();
      deactivateOverlay();
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    submitSupport(values, actions);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setActiveView(event.target.value as View);
  };

  const initialValues = {
    routeId: activeRouteId ?? '',
    note: '',
  };

  const validationSchema = yup.object().shape({});

  return (
    <>
      <OverlayHeader title="Contact Dispatch" />
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form>
          <div className="flex flex-col">
            <FormLabel title="Reason" required />
            <FormikDropdown id="options" options={reasons} onChange={handleOptionChange} />

            {activeView === 'General Feedback' && (
              <>
                <FormLabel title="Route ID" required />
                <FormikInput id="routeId" disabled />

                <FormLabel title="Feedback" />
                <FormikTextarea id="note" />

                <div className="inline-block mt-4">
                  {isLoading ? <div className="loader" /> : <Button label="SUBMIT" type="submit" />}
                </div>
              </>
            )}

            {activeView === 'Change Delivery Schedule' && (
              <>
                <div className="rounded-lg flex items-center justify-center p-8 bg-brand-light-gray">
                  <img src={PHONE} alt="phone" width={100} />
                </div>
                <span className="mt-8 mb-4 font-bold text-center">
                  To change your delivery schedule, please call the number below:
                </span>
                <div className="buttonContainer">
                  <button className="callButton" type="button">
                    1-866-410-7222
                  </button>
                </div>
              </>
            )}
          </div>
        </Form>
      </Formik>
    </>
  );
};
