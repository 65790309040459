import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = { active?: boolean; size?: number };

export const SettingsIcon: FC<Props> = ({ active = false, size = 32 }) => {
  const orange = active ? colors.orange : colors.inactiveGray;
  const black = active ? colors.black : colors.inactiveGray;

  return (
    <svg viewBox="0 0 32 32" width={size} height={size}>
      <g transform="matrix(1.3333333333333333,0,0,1.3333333333333333,0,0)">
        <path
          d="M7.281,9.376a1.308,1.308,0,0,0,1.939,0l.627-.7a1.306,1.306,0,0,1,2.273.942l-.047.934a1.3,1.3,0,0,0,1.371,1.371l.934-.047a1.305,1.305,0,0,1,.941,2.273l-.694.627a1.306,1.306,0,0,0,0,1.939l.694.627a1.305,1.305,0,0,1-.941,2.273l-.934-.047a1.305,1.305,0,0,0-1.371,1.371l.047.934a1.306,1.306,0,0,1-2.273.942l-.627-.7a1.308,1.308,0,0,0-1.939,0l-.627.7a1.306,1.306,0,0,1-2.273-.942l.047-.934a1.307,1.307,0,0,0-1.371-1.371l-.934.047a1.306,1.306,0,0,1-.942-2.273l.695-.627a1.308,1.308,0,0,0,0-1.939l-.695-.627a1.306,1.306,0,0,1,.942-2.273l.934.047a1.305,1.305,0,0,0,1.371-1.371l-.047-.934a1.306,1.306,0,0,1,2.273-.942Z"
          fill="none"
          stroke={black}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M6.375 15.751 A1.875 1.875 0 1 0 10.125 15.751 A1.875 1.875 0 1 0 6.375 15.751 Z"
          fill="none"
          stroke={orange}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M18.638,5a.375.375,0,1,0,.375.375A.375.375,0,0,0,18.638,5h0"
          fill="none"
          stroke={orange}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M21.194,1.848a.75.75,0,0,1,.971.971l-.5,1.286a.749.749,0,0,0,.229.856l1.075.864a.75.75,0,0,1-.355,1.326l-1.363.211a.749.749,0,0,0-.627.626l-.211,1.364a.75.75,0,0,1-1.326.355l-.864-1.075a.751.751,0,0,0-.856-.23l-1.286.5a.75.75,0,0,1-.971-.971l.5-1.286a.749.749,0,0,0-.229-.856l-1.075-.865A.749.749,0,0,1,14.661,3.6l1.363-.211a.751.751,0,0,0,.627-.627L16.862,1.4a.749.749,0,0,1,1.325-.355l.865,1.075a.75.75,0,0,0,.856.229Z"
          fill="none"
          stroke={black}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
