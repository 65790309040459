export const extractError = (error: any): string => {
  const emailError =
    error?.response?.data?.errors?.email[0] && error?.response?.data?.errors?.email[0]
      ? 'This email has already been taken'
      : '';

  const otherError =
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.length > 0
      ? error.response.data.errors[0]
      : 'There was an error';

  const errorToReturn = emailError || otherError;
  return errorToReturn;
};
