import EventEmitter from 'eventemitter3';

// USAGE NOTES
// This event pattern is a great way to de-couple
// disparate parts of your application.
//
// NOTE - eventemitter3 models its API off
// of the Node.js EventEmitter API.
// See: https://github.com/primus/eventemitter3#usage
// See: https://nodejs.org/api/events.html#events_emitter_emit_eventname_args
//
// ======================================
// ===========  EXAMPLE  ================
// ======================================
// const handleTestEvent = (arg1, arg2, argN) => {
//   console.log(arg1); // outputs "arg1"
//   console.log(arg2); // outputs "arg2"
//   console.log(argN); // outputs "argN"
// });
// EE.on("test-event", handleTestEvent);
//
// const i = setInterval(() => {
//   EE.emit("test-event", "arg1", "arg2", "argN");
// }, 5000);
//
// ======================================
// ===========  CLEANUP  ================
// ======================================
// EE.off("test-event", handleTestEvent);
// clearInterval(i);

export const EE = new EventEmitter();
