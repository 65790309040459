import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import { Link } from '@reach/router';
import * as yup from 'yup';
import { Button } from '../../components/Button/Button';
import { FormikInput } from '../../components/FormikInput/FormikInput';
import { TokenRequestFormValues } from './ResetPassword.types';

type Props = {
  loading: boolean;
  error: string | null;
  message: string | null;
  onSubmit: (email: string) => void;
};

const initialValues: TokenRequestFormValues = { email: '' };

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
});

export const PasswordTokenRequestForm: FC<Props> = ({ error, loading, message, onSubmit }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values.email)}
      validationSchema={validationSchema}
    >
      <Form>
        <div className="flex flex-col">
          <span className="text-sm mb-1">Email Address</span>
          <FormikInput id="email" />

          <Link to="/" className="text-xs mb-8 text-brand-orange font-bold">
            Already have an account? Login
          </Link>

          <div className="inline-block mb-4">
            <Button label="SEND RESET LINK" type="submit" disabled={loading} loading={loading} />
          </div>

          {!!error && <span className="text-brand-error mb-8">{error}</span>}
          {!!message && <span className="text-brand-green mb-8">{message}</span>}
        </div>
      </Form>
    </Formik>
  );
};
