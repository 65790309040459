import React, { FC, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import isArray from 'lodash/isArray';
import queryString from 'query-string';
import { PasswordTokenRequestForm } from './PasswordTokenRequestForm';
import { NewPasswordForm } from './NewPasswordForm';
import paceLogo from '../../assets/images/paceLogo.png';
import truckPhoto from '../../assets/images/truckPhoto.png';
import { useResetPassword } from '../../hooks/useResetPassword/useResetPassword';

type Props = RouteComponentProps;

export const ResetPassword: FC<Props> = () => {
  const [resetToken, setPasswordToken] = useState<string | null>(null);
  const { error, loading, message, resetPassword, requestResetToken } = useResetPassword();

  useEffect(() => {
    const params = queryString.parse(location.search); // eslint-disable-line
    if (params && params.reset_password_token && !isArray(params.reset_password_token)) {
      setPasswordToken(params.reset_password_token);
    }
  }, []);

  return (
    <div className="flex px-0 pt-16 lg:pt-24 xl:pt-32 lg:px-48 xl:px-64">
      <div className="rounded-lg flex flex-row flex-1 overflow-hidden shadow-lg mb-32">
        <div className="flex flex-col flex-1 h-full bg-white px-10 md:px-32 lg:px-20 lg:mb-24 xl:mb-32 pt-20 pb-8">
          <div className="flex-1">
            <img src={paceLogo} alt="pace logo" className="h-16 mb-8" />
            <h1 className="text-lg font-bold mb-4">Reset Password</h1>
            {!resetToken && (
              <PasswordTokenRequestForm
                onSubmit={requestResetToken}
                error={error}
                loading={loading}
                message={message}
              />
            )}
            {!!resetToken && (
              <NewPasswordForm
                error={error}
                loading={loading}
                onSubmit={resetPassword}
                resetToken={resetToken}
                message={message}
              />
            )}
          </div>
          <div>
            <span className="text-sm">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Don't have an account?{' '}
              <Link to="/sign-up" className="text-brand-orange font-bold">
                Sign up now.
              </Link>
            </span>
          </div>
        </div>
        <div className="hidden lg:flex lg:flex-1">
          <img src={truckPhoto} alt="semi-truck" className="object-cover h-full w-full" />
        </div>
      </div>
    </div>
  );
};
