import { CustomerDictionary } from '../hooks/useUsers/useUsers.types';

export const byLastName = (a: User, b: User): -1 | 0 | 1 => {
  const aSurname = a.attributes.lastName;
  const aFirst = a.attributes.firstName;
  const bSurname = b.attributes.lastName;
  const bFirst = b.attributes.firstName;

  if (aSurname < bSurname) return -1;
  if (aSurname > bSurname) return 1;
  if (aSurname === bSurname && aFirst < bFirst) return -1;
  if (aSurname === bSurname && aFirst > bFirst) return 1;
  return 0;
};

export const getCompanyName = (user: User, customerDictionary: CustomerDictionary): string => {
  const companyId = user.relationships.customer.data?.id ?? '';
  const companyName = companyId ? customerDictionary[companyId].attributes.name : '';

  if (!companyId || !companyName) return '';
  return companyName;
};

export const removeUserFromState = (userId: string, prevUsers: User[]): User[] => {
  return prevUsers.filter((user) => user.id !== userId);
};
