import React, { FC, useContext } from 'react';
import { Link } from '@reach/router';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Button } from '../../components/Button/Button';
import { FormikInput } from '../../components/FormikInput/FormikInput';
import { AuthContext } from '../../context/AuthContext/AuthContext';

type Props = {
  onSubmit: (values: FormValues) => void;
};

export type FormValues = {
  email: string;
  password: string;
};

const initialValues: FormValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter your email address'),
  password: yup.string().required('Please enter your password'),
});

export const LoginForm: FC<Props> = ({ onSubmit }) => {
  const { error, loading } = useContext(AuthContext);
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Form>
        <div className="flex flex-col">
          <span className="text-sm mb-1">Email Address</span>
          <FormikInput id="email" />

          <span className="text-sm mb-1">Password</span>
          <FormikInput id="password" password />

          <Link to="/reset-password" className="text-xs mb-8 text-brand-orange font-bold">
            Forgot password?
          </Link>

          <div className="inline-block mb-2">
            <Button label="LOGIN" type="submit" disabled={loading} />
          </div>

          {error ? <span className="text-brand-error mb-8">{error}</span> : null}
        </div>
      </Form>
    </Formik>
  );
};
