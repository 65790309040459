import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = {
  color?: string;
  size?: number;
};

export const ChevronRightIcon: FC<Props> = ({ size = 25, color = colors.black }) => {
  return (
    <svg viewBox="0 0 25 25" width={size} height={size}>
      <g transform="matrix(1.0416666666666667,0,0,1.0416666666666667,0,0)">
        <path
          d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
