import { baseService } from '../baseService';

type DriverReviewValues = {
  driverId: string;
  rating: number;
  feedback?: string;
};

export const postDriverFeeback = (values: DriverReviewValues): Promise<Review> =>
  baseService.post('reviews', { review: values });
