import React, { FC } from 'react';

type Props = { title: string };

export const OverlayHeader: FC<Props> = ({ title }) => {
  return (
    <div className="border-b-2 border-brand-border-gray pb-2 mb-4">
      <span className="text-xl font-bold">{title}</span>
    </div>
  );
};
