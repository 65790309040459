import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = { active?: boolean; size?: number };

export const RoutesIcon: FC<Props> = ({ active = false, size = 32 }) => {
  const orange = active ? colors.orange : colors.inactiveGray;
  const black = active ? colors.black : colors.inactiveGray;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2693 28H20.7319"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2693 28.0001H15V19.0001H27V27.0001"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 27.0001V21.2788L29.1627 21.9455C29.6813 22.0897 30.1399 22.3968 30.4707 22.8215C30.8015 23.2462 30.9871 23.766 31 24.3041V27.3855C30.9931 27.5555 30.919 27.7158 30.794 27.8313C30.669 27.9468 30.5034 28.008 30.3333 28.0015H28.7333"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 14.0441V8.65212C13 8.4792 12.9313 8.31336 12.809 8.19109C12.6868 8.06882 12.5209 8.00012 12.348 8.00012H7.65333C7.5676 7.99995 7.48267 8.01668 7.40341 8.04937C7.32416 8.08206 7.25212 8.13006 7.19144 8.19062C7.13075 8.25118 7.08261 8.32311 7.04976 8.40231C7.01691 8.4815 7 8.56639 7 8.65212V14.0441"
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7L10 1L19 7"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 14.0429V5.66687"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5.66687L3.004 14.0442"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 29C17 29.5304 17.2107 30.0391 17.5858 30.4142C17.9609 30.7893 18.4696 31 19 31C19.5304 31 20.0391 30.7893 20.4142 30.4142C20.7893 30.0391 21 29.5304 21 29C21 28.4696 20.7893 27.9609 20.4142 27.5858C20.0391 27.2107 19.5304 27 19 27C18.4696 27 17.9609 27.2107 17.5858 27.5858C17.2107 27.9609 17 28.4696 17 29Z"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 29C25 29.5304 25.2107 30.0391 25.5858 30.4142C25.9609 30.7893 26.4696 31 27 31C27.5304 31 28.0391 30.7893 28.4142 30.4142C28.7893 30.0391 29 29.5304 29 29C29 28.4696 28.7893 27.9609 28.4142 27.5858C28.0391 27.2107 27.5304 27 27 27C26.4696 27 25.9609 27.2107 25.5858 27.5858C25.2107 27.9609 25 28.4696 25 29Z"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 17.0001V18.0001"
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22.0002V23.0002"
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 27C9 27.5304 9.21071 28.0391 9.58579 28.4142C9.96086 28.7893 10.4696 29 11 29"
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
