import React, { FC, useContext } from 'react';
import { Link } from '@reach/router';
import { BellIcon } from '../../assets/icons/BellIcon';
import { NotificationContext } from '../../context/NotificationContext/NotificationContext';

export const NotificationButton: FC = () => {
  const { unreadNotifications } = useContext(NotificationContext);

  return (
    <Link to="/notifications">
      <div className="items-center justify-center relative p-1">
        {unreadNotifications && (
          <div className="h-2 w-2 rounded-full bg-brand-orange absolute top-0 left-0" />
        )}
        <BellIcon />
      </div>
    </Link>
  );
};
