import React, { FC, createContext, useReducer } from 'react';
import { State, OverlayAction, OverlayView, Provider } from './OverlayContext.types';
import { NullDriver } from '../../hooks/useRoutes/useRoutes.types';

const INITIAL_STATE: State = {
  activeDriver: NullDriver,
  activeRouteId: null,
  activeUserId: null,
  overlayActive: false,
  overlayView: 'contactDispatch',
  deleteDriverSuccessCallback: () => {},
};

const reducer = (state: State, action: OverlayAction): State => {
  switch (action.type) {
    case 'CONTACT_DISPATCH':
      return {
        ...state,
        activeRouteId: action.payload,
        overlayActive: true,
        overlayView: 'contactDispatch',
      };
    case 'DELETE_USER':
      return {
        ...state,
        activeUserId: action.payload.userId,
        deleteDriverSuccessCallback: action.payload.successCallback,
        overlayActive: true,
        overlayView: 'confirmUserDelete',
      };
    case 'RATE_DRIVER':
      return {
        ...state,
        activeDriver: action.payload,
        overlayActive: true,
        overlayView: 'rateDriver',
      };
    case 'SET_OVERLAY_ACTIVE':
      return { ...state, overlayActive: true, overlayView: action.payload };
    case 'SET_OVERLAY_INACTIVE':
      return {
        ...state,
        activeDriver: NullDriver,
        activeRouteId: null,
        activeUserId: null,
        overlayActive: false,
      };
    default:
      return state;
  }
};

export const OverlayContext = createContext<Provider>({} as Provider);

export const OverlayProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const activateOverlay = (overlayView: OverlayView) => {
    dispatch({ type: 'SET_OVERLAY_ACTIVE', payload: overlayView });
  };

  const deactivateOverlay = () => {
    dispatch({ type: 'SET_OVERLAY_INACTIVE' });
  };

  const openDeleteUserConfirmation = (userId: string, successCallback?: () => void) => {
    dispatch({
      type: 'DELETE_USER',
      // eslint-disable-next-line
      payload: { userId, successCallback: successCallback ? successCallback : () => {} },
    });
  };

  const openRateDriverView = (driver: Driver) => {
    dispatch({ type: 'RATE_DRIVER', payload: driver });
  };

  const openContactDispatchView = (routeId: string) => {
    dispatch({ type: 'CONTACT_DISPATCH', payload: routeId });
  };

  return (
    <OverlayContext.Provider
      value={{
        ...state,
        activateOverlay,
        deactivateOverlay,
        openContactDispatchView,
        openDeleteUserConfirmation,
        openRateDriverView,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
};
