export const colors = {
  black: '#231f20',
  borderGray: '#e9e9e9',
  error: '#e92121',
  inactiveGray: '#9aa4b3',
  lightBlack: '#706467',
  lightGray: '#f3f2f0',
  'green-100': '#98c597',
  green: '#308a2f',
  orange: '#ec5328',
};
