import React, { FC, useContext } from 'react';
import { PendingIcon } from './PendingIcon';
import { RateDriverButton } from './RateDriverButton';
import { CheckIcon } from '../../assets/icons';
import { colors } from '../../styles/colors';
import { EmptyRouteDetail } from './EmptyRouteDetail';
import { Button } from '../../components/Button/Button';
import { OverlayContext } from '../../context/OverlayContext/OverlayContext';
import { NullDriver } from '../../hooks/useRoutes/useRoutes.types';
import { formatDate } from '../../utils/dateUtils';

type RouteDetailProps = {
  activeRoute: Route;
  activeRouteId: string | null;
  clearActiveRoute: () => void;
  deliveriesLoading: boolean;
  deliveryDictionary: NormalizedCollection<Delivery>;
  driverDictionary: NormalizedCollection<Driver>;
};

type DeliveryProps = {
  delivery: Delivery;
  first: boolean;
  index: number;
  last: boolean;
  iconSize?: number;
};

const Delivery: FC<DeliveryProps> = ({ iconSize = 20, last, delivery, first, index }) => {
  const { address, completed, currentEta, onTime, originalEta } = delivery.attributes;

  return (
    <>
      <div className="w-full flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <div className="mr-3">
            {completed ? <CheckIcon size={iconSize} /> : <PendingIcon size={iconSize.toString()} />}
          </div>
          <div className="flex flex-col">
            <span className="text-md">{first ? 'Departed Origin' : `Stop ${index}`}</span>
            <span className="text-xs">{address}</span>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <span className="w-20 text-xs text-right">{formatDate(originalEta, 'TIME')}</span>
          <span
            className={`w-20 text-xs text-right ${
              onTime ? 'text-brand-green' : 'text-brand-error'
            }`}
          >
            {formatDate(currentEta, 'TIME')}
          </span>
        </div>
      </div>
      {last ? null : (
        <div style={{ paddingLeft: iconSize / 2 - 1.5 }}>
          <div
            style={{
              borderLeftWidth: 3,
              borderLeftStyle: 'dotted',
              height: 25,
              borderColor: colors.inactiveGray,
            }}
          />
        </div>
      )}
    </>
  );
};

export const RouteDetail: FC<RouteDetailProps> = ({
  activeRoute,
  activeRouteId,
  clearActiveRoute,
  deliveriesLoading,
  deliveryDictionary,
  driverDictionary,
}) => {
  const { openContactDispatchView, openRateDriverView } = useContext(OverlayContext);
  const driverId = activeRoute.relationships.driver.data.id ?? '';
  const driver = driverDictionary[driverId] ?? NullDriver;
  const deliveries = activeRoute.relationships.deliveries.data ?? [];

  const onRateDriverClick = () => {
    if (!driver) return;
    openRateDriverView(driver);
  };

  return (
    <>
      <div
        className={`${
          activeRouteId ? 'flex' : 'hidden'
        } lg:flex flex-1 flex-col w-full lg:rounded-lg lg:shadow-lg bg-white px-4 lg:px-8 py-2 mt-4 relative`}
      >
        {!!activeRouteId && (
          <div className="w-full">
            <div className="lg:hidden">
              <Button label="BACK" theme="light" onClick={clearActiveRoute} />
            </div>

            <div className="flex flex-row justify-end">
              <span className="w-20 text-sm text-right text-brand-inactive-gray">Estimated</span>
              <span className="w-20 text-sm text-right text-brand-inactive-gray">Actual</span>
            </div>
          </div>
        )}
        {activeRouteId && !deliveriesLoading ? (
          <>
            {deliveries.map((item, index, arr) => {
              const delivery = deliveryDictionary[item.id];
              const last = index === arr.length - 1;

              if (!deliveryDictionary[item.id]) return null;
              return (
                <Delivery
                  delivery={delivery}
                  first={index === 0}
                  index={index}
                  key={index.toString()}
                  last={last}
                />
              );
            })}

            <RateDriverButton
              driverName={`${driver.attributes.firstName ?? ''} ${
                driver.attributes.lastName ?? ''
              }`}
              driverPhoto={driver.attributes.image}
              onClick={onRateDriverClick}
            />
          </>
        ) : (
          <EmptyRouteDetail />
        )}
        <div className="flex-1" />

        {activeRouteId && (
          <div className="w-full flex flex-col lg:flex-row lg:justify-end pt-8">
            <Button
              label="CONTACT DISPATCH"
              onClick={() => openContactDispatchView(activeRoute.id)}
              style={{ marginRight: 0, marginBottom: '1rem' }}
            />
          </div>
        )}
      </div>
    </>
  );
};
