import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import trim from 'lodash/trim';
import { formatDate } from '../../utils/dateUtils';
import './SingleNotification.scss';

type Props = {
  index: number;
  notification: Alert;
};

const DELIVERY_TEXT = "Your Pace shipment was delivered! We'd love your feedback.";

export const SingleNotification: FC<Props> = ({ index, notification }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const { message, createdAt } = notification.attributes;

  const DeliveryNotificationText = () => {
    const url = trim(message.split('here:')[1]);

    if (message.includes(DELIVERY_TEXT) && !!url) {
      return (
        <span>
          {"Your Pace shipment was delivered! We'd love your feedback. "}
          <a href={url} className="text-blue-600 hover:text-blue-600 underline">
            Rate your driver here.
          </a>
        </span>
      );
    }

    return <span className={isDesktop ? '' : 'responsive-body-text'}>{message}</span>;
  };

  return (
    <div
      className={`notification-container ${index % 2 ? 'dark-background' : ''} ${
        isDesktop ? 'normalContainer' : 'responsive-container'
      }`}
    >
      <DeliveryNotificationText />
      <span className="date-text">{formatDate(createdAt, 'VERBOSE')}</span>
    </div>
  );
};
