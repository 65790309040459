import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = {
  active?: boolean;
  size?: number;
};

export const AlertIcon: FC<Props> = ({ active = false, size = 18 }) => {
  const color = active ? colors.error : colors.black;

  return (
    <svg viewBox="0 0 25 25" width={size} height={size}>
      <g transform="matrix(1.0416666666666667,0,0,1.0416666666666667,0,0)">
        <path
          d="M13.313,2.275a1.5,1.5,0,0,0-2.626,0l-9.75,18A1.5,1.5,0,0,0,2.25,22.5h19.5a1.5,1.5,0,0,0,1.313-2.225Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M12,18a.375.375,0,0,1,.375.375"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M11.625,18.375A.375.375,0,0,1,12,18"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M12,18.75a.375.375,0,0,1-.375-.375"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M12.375,18.375A.375.375,0,0,1,12,18.75"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M12 15L12 8.25"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
