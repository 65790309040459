import React, { FC } from 'react';

type Props = {
  size?: number;
};

export const MenuIcon: FC<Props> = ({ size = 18 }) => {
  return (
    <svg viewBox={`0 0 ${size} ${size}`} width={size} height={size}>
      <g transform="matrix(0.75,0,0,0.75,0,0)">
        <path
          d="M2.25 18.003L21.75 18.003"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M2.25 12.003L21.75 12.003"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M2.25 6.003L21.75 6.003"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
