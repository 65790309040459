import React, { FC } from 'react';
import { AlertIcon, TruckIcon } from '../../assets/icons';
import { Button } from '../../components/Button/Button';
import { RoutesHookReturn } from '../../hooks/useRoutes/useRoutes.types';

type RouteListProps = {
  activeRouteId: string | null;
  currentPage: number;
  fetchingMoreRoutes: boolean;
  getRoutes: RoutesHookReturn['getRoutes'];
  loading: boolean;
  routes: Route[];
  setActiveRouteId: (routeId: string) => void;
  totalPages: number;
};

type RouteProps = {
  activeIndex: number;
  index: number;
  onClick: (routeId: string) => void;
  routeId: string;
  completed: boolean;
  minLate: number;
};

const getBorderStyling = (values: { activeIndex: number; index: number }): string => {
  const { activeIndex, index } = values;
  const grayBorder = 'border-brand-border-gray';
  const whiteBorder = 'border-white';

  if (index === 0 && index === activeIndex) return `${whiteBorder} border-b-2`; // if active and first in list, no borders
  if (index === activeIndex) return `${whiteBorder} border-b-2`; // if active route, no borders
  if (index === activeIndex - 1) return `${whiteBorder} border-b-2`; // if before active, no border
  return `${grayBorder} border-b-2`;
};

const LoadingState: FC = () => {
  return (
    <div className="w-full h-40 flex items-center justify-center">
      <span className="text-brand-inactive-gray">Loading Routes...</span>
    </div>
  );
};

const EmptyState: FC = () => {
  return (
    <div className="w-full h-40 flex flex-col items-center justify-center">
      <div className="mb-4">
        <TruckIcon />
      </div>
      <span className="text-brand-inactive-gray">No active routes.</span>
    </div>
  );
};

const Route: FC<RouteProps> = ({ activeIndex, index, onClick, routeId, minLate, completed }) => {
  const border = getBorderStyling({ activeIndex, index });
  const opacity = completed ? 'opacity-50' : '';
  const late = minLate > 0;
  const statusText = `text-xs ${late ? 'text-brand-error' : 'text-brand-green'}`;

  return (
    <div className={`${border}`}>
      <button
        type="button"
        id={`route ${routeId} button`}
        aria-label="User menu"
        onClick={() => onClick(routeId)}
        style={{ outline: 'none' }}
        className={`${
          index === activeIndex ? 'bg-brand-light-gray' : ''
        } w-full h-12 pl-4 pr-4 py-1 rounded-full flex flex-row justify-between items-center`}
      >
        <div className={`flex flex-row flex-1 justify-start items-center ${opacity}`}>
          <span className="text-md leading-none ">{`Route ${routeId}`}</span>
        </div>
        {late && (
          <div className={`mr-2 ${completed ? 'opacity-50' : ''}`}>
            <AlertIcon size={16} active />
          </div>
        )}
        <div className={`w-16 flex flex-col items-center justify-center ${opacity}`}>
          {completed ? <span className={statusText}>Completed</span> : null}
          <span className={statusText}>{late ? `${minLate.toString()} min late` : 'On time'}</span>
        </div>
      </button>
    </div>
  );
};

export const RouteList: FC<RouteListProps> = ({
  activeRouteId,
  currentPage,
  fetchingMoreRoutes,
  getRoutes,
  loading,
  routes,
  setActiveRouteId,
  totalPages,
}) => {
  const handleClick = (): void => {
    getRoutes('getMore');
  };

  return (
    <div
      className={`${
        activeRouteId ? 'hidden' : 'block'
      } lg:block lg:mr-10 lg:w-64 lg:overflow-y-scroll lg:max-h-screen pr-8`}
    >
      {loading && <LoadingState />}
      {!loading && routes.length > 0 && (
        <>
          {routes.map((route, index, arr) => {
            const activeIndex = arr.findIndex((item) => item.id === activeRouteId?.toString());

            return (
              <Route
                activeIndex={activeIndex}
                index={index}
                onClick={setActiveRouteId}
                key={route.id}
                routeId={route.id}
                completed={route.attributes.completed}
                minLate={route.attributes.minutesLate}
              />
            );
          })}

          {currentPage < totalPages && (
            <div className="w-full justify-center flex my-6">
              <Button
                disabled={fetchingMoreRoutes}
                label={fetchingMoreRoutes ? 'LOADING' : 'LOAD MORE'}
                onClick={handleClick}
              />
            </div>
          )}
        </>
      )}
      {!loading && routes.length === 0 && <EmptyState />}
    </div>
  );
};
