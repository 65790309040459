import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = {
  size?: number;
};

export const TruckIcon: FC<Props> = ({ size = 32 }) => {
  const color = colors.inactiveGray;

  return (
    <svg viewBox="0 0 32 32" width={size} height={size}>
      <g transform="matrix(1.3333333333333333,0,0,1.3333333333333333,0,0)">
        <path
          d="M23.25,13.5V6a1.5,1.5,0,0,0-1.5-1.5h-12A1.5,1.5,0,0,0,8.25,6v6"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M8.25,12V6h-3a4.5,4.5,0,0,0-4.5,4.5v6A1.5,1.5,0,0,0,2.25,18H3"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M.75,12h3a1.5,1.5,0,0,0,1.5-1.5V6"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M5.250 17.250 A2.250 2.250 0 1 0 9.750 17.250 A2.250 2.250 0 1 0 5.250 17.250 Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M17.250 17.250 A2.250 2.250 0 1 0 21.750 17.250 A2.250 2.250 0 1 0 17.250 17.250 Z"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M12 18L15 18"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
