import React, { FC, useEffect, useState } from 'react';
import { ChevronRightIcon, ProfileIcon } from '../../assets/icons';
import './Routes.scss';

type Props = {
  driverName: string;
  driverPhoto: any;
  onClick: () => void;
};

export const RateDriverButton: FC<Props> = ({ driverName, driverPhoto, onClick }) => {
  const [formattedImg, setFormattedImg] = useState('');

  const formatImg = () => {
    const base64String = btoa(String.fromCharCode(...new Uint8Array(JSON.parse(driverPhoto))));
    setFormattedImg(base64String);
  };

  useEffect(() => {
    formatImg();
  }, []);

  return (
    <button
      type="button"
      className="rateDriverButton"
      onClick={onClick}
      style={{ outline: 'none' }}
    >
      <div className="leftContainer">
        {!driverPhoto && (
          <div className="placeholderContainer">
            <ProfileIcon size={50} />
          </div>
        )}

        {!!driverPhoto && (
          <div className="photoContainer">
            <img src={`data:image/png;base64,${formattedImg}`} alt="driver" />
          </div>
        )}

        <div className="textContainer">
          <span>Rate Your Driver</span>
          <span className="driverName">{driverName}</span>
        </div>
      </div>
      <ChevronRightIcon size={18} />
    </button>
  );
};
