import React, { ChangeEvent, FC } from 'react';
import './Slider.scss';

type Props = {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const Slider: FC<Props> = ({ checked, onChange }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className="switch">
    <input id="toggle" type="checkbox" checked={checked} onChange={onChange} />
    <span className="slider round" />
  </label>
);
