import { AxiosResponse } from 'axios';
import { baseService } from '../baseService';

interface SupportResponse {
  data: User;
  headers: {
    'access-token': string;
  };
}

export const sendSupport = async (values: {
  note: string;
  routeId: string;
}): Promise<AxiosResponse<SupportResponse>> =>
  baseService.post('/support_tickets', {
    support_ticket: {
      note: values?.note ?? '',
      cxt_route_id: values?.routeId ?? '',
    },
  });
