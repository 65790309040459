import React, { FC, useContext, useState } from 'react';
import { Link, useNavigate } from '@reach/router';
import { Transition } from '@tailwindui/react';
import { ProfileIcon } from '../../assets/icons';
import { AuthContext } from '../../context/AuthContext/AuthContext';

export const ProfileDropdown: FC = () => {
  const { logout } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = () => {
    logout(() => {
      navigate('/');
    });
  };

  return (
    <div className="ml-3 relative hidden lg:block">
      <div>
        <button
          type="button"
          className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <ProfileIcon />
        </button>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {(ref) => (
          <div
            ref={ref}
            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10"
          >
            <div
              className="py-1 rounded-md bg-white shadow-xs"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <Link
                to="/profile"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                role="menuitem"
              >
                <span className="text-black">Your profile</span>
              </Link>
              {/* eslint-disable-next-line */}
              <div
                onClick={handleSignOut}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer hover:text"
              >
                <span className="text-black hover:text-black">Sign out</span>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
};
