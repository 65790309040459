import snakeCase from 'lodash/snakeCase';
import { baseService } from '../baseService';

type TokenRequestReturn = {
  success: boolean;
  message: string;
};

type PasswordResetReturn = {
  success: boolean;
  data: {
    provider: 'email';
    allow_password_change: boolean;
    id: number;
    email: string;
    phone: string;
    settings: any;
    uid: string;
    status: 'active';
    created_at: string;
    updated_at: string;
    first_name: string;
    last_name: string;
    role: 'customer_user';
    customer_id: null;
  };
  message: 'Your password has been successfully updated.';
};

export type UserFormValues = {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phone: string;
};

export type UserInviteValues = {
  customerId: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  phone: string;
};

export const deleteUser = (userId: string): Promise<void> => baseService.delete(`/users/${userId}`);

export const patchUser = (userId: string, values: UserFormValues): Promise<ApiResponse<User>> =>
  baseService.patch(`users/${userId}`, {
    user: values,
  });

export const getUsers = (page: string): Promise<ApiResponse<User[]>> => {
  return baseService.get(`/users?page=${page}`);
};

export const postPasswordResetRequest = (email: string): Promise<ApiResponse<TokenRequestReturn>> =>
  baseService.post('/customer/password', {
    email,
    redirectUrl: process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL,
  });

export const putNewPassword = (
  password: string,
  passwordConfirmation: string,
  resetPasswordToken: string,
): Promise<ApiResponse<PasswordResetReturn>> =>
  baseService.put('/customer/password', {
    password,
    passwordConfirmation,
    resetPasswordToken,
  });

export const patchPasswordForLoggedInUser = async (
  userId: string,
  currentPassword: string,
  password: string,
  passwordConfirmation: string,
): Promise<void> => {
  return baseService.patch(`/users/${userId}/password`, {
    user: {
      currentPassword,
      password,
      passwordConfirmation,
    },
  });
};

export const postUserInvite = (values: UserInviteValues): Promise<ApiResponse<User>> => {
  const { lastName, firstName, email, role, customerId, phone } = values;
  return baseService.post('/users/invite', {
    user: { customerId, email, firstName, lastName, phone, role: snakeCase(role) },
    redirectUrl: process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL,
  });
};
