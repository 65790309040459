import { RouteComponentProps } from '@reach/router';
import React, { FC, useContext } from 'react';
import { Button } from '../../components/Button/Button';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import { UserTable } from './UserTable';
import { OverlayContext } from '../../context/OverlayContext/OverlayContext';
import { AuthContext } from '../../context/AuthContext/AuthContext';

type Props = RouteComponentProps;

export const Users: FC<Props> = ({ path }) => {
  const { userInfo } = useContext(AuthContext);
  const { activateOverlay } = useContext(OverlayContext);

  return (
    <MainLayout path={path}>
      {userInfo.attributes.role !== 'customer_user' && (
        <div className="w-full flex flex-row justify-end mb-4">
          <Button label="INVITE NEW USER" onClick={() => activateOverlay('inviteUser')} />
        </div>
      )}
      <UserTable />
    </MainLayout>
  );
};
