import React, { FC } from 'react';
import { ErrorMessage, Field } from 'formik';

type Props = {
  id: string;
  password?: boolean;
  placeholder?: string;
  disabled?: boolean;
};

export const FormikInput: FC<Props> = ({ password = false, id, placeholder, disabled }) => {
  return (
    <div className="w-full mb-4">
      <Field
        id={id}
        name={id}
        type={password ? 'password' : 'text'}
        placeholder={placeholder}
        disabled={disabled}
        className="rounded-full border border-solid border-brand-border-gray h-8 focus:shadow-outline focus:outline-none pl-4 block w-full"
      />

      <ErrorMessage name={id} component="span" className="text-sm text-brand-error" />
    </div>
  );
};
