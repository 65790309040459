import { AxiosError, AxiosResponse } from 'axios';
import { EE } from '../eventEmitter';
import { LOGOUT_EVENT } from '../../utils/constants';

export const handleErrorResponse = (error: AxiosError): Promise<never> => {
  const errorCode = error.response?.status ?? 0;
  if (errorCode === 401) EE.emit(LOGOUT_EVENT);
  return Promise.reject(error);
};

export const handleSuccessResponse = (response: AxiosResponse<any>): AxiosResponse<any> => response;
