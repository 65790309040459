import React, { FC, useState, useContext } from 'react';
import { RadioButtonRow } from '../../../components/RadioButtonRow/RadioButtonRow';
import './Homescreen.scss';
import { homescreenOptions } from './HomescreenUtils';
import { AuthContext } from '../../../context/AuthContext/AuthContext';

type Option = {
  title: string;
  value: string;
  id: string;
};

type HomescreenOptions = 'Dashboard' | 'Routes' | 'News' | 'Support' | InitialHomescreen | string;

export const Homescreen: FC = () => {
  const { userInfo, changeHomescreen } = useContext(AuthContext);
  const [currentOption, setCurrentOption] = useState(
    userInfo?.attributes?.settings?.homeScreen ?? 'dashboard',
  );
  const handleOptionChange = (option: HomescreenOptions) => {
    changeHomescreen(option);
    setCurrentOption(option);
  };

  // eslint-disable-next-line
  const handleFormSubmit = (event: any) => {
    event.preventDefault();
  };

  return (
    <div className="container" style={{ maxWidth: 700 }}>
      <form onSubmit={handleFormSubmit}>
        <h1 className="title">What would you like to set as your Homescreen?</h1>
        <p style={{ marginBottom: 20 }}>
          This will be the screen that is open by default every time you open the app.
        </p>
        {homescreenOptions.map((option: Option) => (
          <RadioButtonRow
            key={option.title}
            title={option.title}
            value={option.value}
            onChange={(event) => handleOptionChange(event.target.value)}
            checked={currentOption === option.value}
          />
        ))}
      </form>
    </div>
  );
};
