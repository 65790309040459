import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import './News.scss';
import { StoryCard } from '../../components/StoryCard/StoryCard';
import { getNewsPosts } from '../../services/news/news.service';

type Props = RouteComponentProps;

export const News: FC<Props> = ({ path }) => {
  const [posts, setPosts] = React.useState<Post[]>([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    getNewsPosts(page).then((res) => {
      setPosts((p) => p.concat(res));
      setLoading(false);
    });
  }, [page]);
  const loadMore = () => {
    setPage(page + 1);
  };
  return (
    <MainLayout path={path}>
      <div className="px-4 lg:px-0">
        <div className="cards">
          {posts.map((post) => (
            <StoryCard post={post} key={`${post.title.rendered}-${post.date}`} />
          ))}
        </div>
        <div className="buttonContainer">
          {!loading ? (
            <button onClick={() => loadMore()} type="button" className="loadMore">
              LOAD MORE...
            </button>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="loader" />
              <p className="loading">LOADING</p>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
