import React, { ChangeEvent, FC, useContext } from 'react';
import { Slider } from '../../../components/Slider/Slider';
import { AuthContext } from '../../../context/AuthContext/AuthContext';
import './Notifications.scss';

type NotificationRowProps = {
  title: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  id: string;
};

const NotificationRow: FC<NotificationRowProps> = ({ title, checked, onChange, id }) => (
  <div className="notificationRowContainer">
    <p className="toggleTitle">{title}</p>
    <Slider checked={checked} onChange={onChange} />
  </div>
);

export const Notifications: FC = () => {
  const { userInfo, changeEmailNotifications, changeTextNotifications } = useContext(AuthContext);
  return (
    <div style={{ maxWidth: 300 }}>
      <NotificationRow
        title="Text Notifications"
        checked={userInfo?.attributes?.settings?.textNotifications}
        onChange={() => {
          changeTextNotifications(!userInfo?.attributes?.settings?.textNotifications);
        }}
        id="textNotesToggle"
      />
      <NotificationRow
        title="Email Notifications"
        checked={userInfo?.attributes?.settings?.emailNotifications}
        onChange={() => {
          changeEmailNotifications(!userInfo?.attributes?.settings?.emailNotifications);
        }}
        id="emailNotesToggle"
      />
    </div>
  );
};
