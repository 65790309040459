import React, { FC, useContext, useState } from 'react';
import { OverlayContext } from '../../context/OverlayContext/OverlayContext';
import { useUsers } from '../../hooks/useUsers/useUsers';
import { Button } from '../Button/Button';

export const DeleteUserModal: FC = () => {
  const { activeUserId, deactivateOverlay, deleteDriverSuccessCallback } = useContext(
    OverlayContext,
  );
  const { error, loading, removeUser } = useUsers();
  const [localError, setLocalError] = useState('');

  const handleDelete = () => {
    if (!activeUserId) {
      setLocalError('Something went wrong. Please try again later.');
      return;
    }

    removeUser(activeUserId, () => {
      setLocalError('');
      deleteDriverSuccessCallback();
      deactivateOverlay();
    });
  };

  return (
    <div className="absolute top-0 left-0 h-screen w-screen flex flex-1 justify-center items-center bg-transparent">
      <div className="bg-white rounded p-8 z-20 flex flex-col">
        <span className="font-bold text-lg">Are you sure you want to delete this user?</span>

        {!error && !localError && <span className="mb-6">This action cannot be undone.</span>}
        {!!localError && <span className="mb-6 text-brand-error">{localError}</span>}
        {!!error && <span className="mb-6 text-brand-error">{error}</span>}

        <div className="flex flex-row">
          <div className="inline mr-3">
            <Button
              label="NEVERMIND"
              disabled={loading}
              type="submit"
              onClick={deactivateOverlay}
            />
          </div>
          <div className="inline">
            <Button
              label={loading ? 'DELETING' : 'YES, DELETE USER'}
              disabled={loading}
              type="button"
              onClick={handleDelete}
              theme="light"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
