/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import moment from 'moment';
import './StoryCard.scss';
import { stripHtml, findCoverImg } from '../../utils/newsUtils';
import PaceCover from '../../assets/images/paceNewsCover.png';

type Props = {
  post: Post;
};

export const StoryCard: FC<Props> = ({ post }) => {
  const [postImg, setPostImg] = React.useState('');
  React.useEffect(() => {
    setPostImg(findCoverImg(post?.content?.rendered ?? ''));
  }, [post]);

  const openNewsPost = (link: string) => window.open(link);

  return (
    <div
      className="card"
      onClick={() => openNewsPost(post?.link)}
      onKeyDown={() => openNewsPost(post?.link)}
      role="link"
      tabIndex={0}
    >
      <div className="imgBackground">
        <img src={postImg === '' ? PaceCover : postImg} className="cardImg" alt="postImg" />
      </div>
      <caption>{moment(post?.date ?? '').format('MMMM Do, YYYY')}</caption>
      <h1 className="title">{stripHtml(post?.title?.rendered ?? '')}</h1>
      <p className="subtitle">{stripHtml(post?.excerpt?.rendered)}</p>
    </div>
  );
};
