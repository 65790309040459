import React, { FC, useContext, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { MainLayout } from '../../components/MainLayout/MainLayout';
import { SingleNotification } from './SingleNotification';
import { NotificationContext } from '../../context/NotificationContext/NotificationContext';
import { EmptyState } from './EmptyState';

type Props = RouteComponentProps;

export const Notifications: FC<Props> = ({ path }) => {
  const { loading, notifications, fetchNotifications, currentPage, totalPages } = useContext(
    NotificationContext,
  );

  useEffect(() => {
    fetchNotifications('initial');
  }, []);

  const handleGetMore = () => {
    fetchNotifications('getMore');
  };

  return (
    <MainLayout path={path}>
      {notifications.length === 0 && !loading && <EmptyState />}

      {notifications.length > 0 &&
        notifications.map((notification, index) => (
          <SingleNotification notification={notification} index={index} key={notification.id} />
        ))}

      {totalPages > 1 && currentPage !== totalPages && (
        <div className="buttonContainer">
          <button onClick={handleGetMore} type="button" className="loadMore">
            LOAD MORE...
          </button>
        </div>
      )}
    </MainLayout>
  );
};
