import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = {
  active?: boolean;
  size?: number;
};

export const UsersIcon: FC<Props> = ({ active = false, size = 32 }) => {
  const orange = active ? colors.orange : colors.inactiveGray;
  const black = active ? colors.black : colors.inactiveGray;

  return (
    <svg viewBox="0 0 32 32" width={size} height={size}>
      <g transform="matrix(1.3333333333333333,0,0,1.3333333333333333,0,0)">
        <path
          d="M2.250 6.000 A2.250 2.250 0 1 0 6.750 6.000 A2.250 2.250 0 1 0 2.250 6.000 Z"
          fill="none"
          stroke={black}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M4.5,9.75A3.75,3.75,0,0,0,.75,13.5v2.25h1.5l.75,6H6"
          fill="none"
          stroke={black}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M17.250 6.000 A2.250 2.250 0 1 0 21.750 6.000 A2.250 2.250 0 1 0 17.250 6.000 Z"
          fill="none"
          stroke={black}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M19.5,9.75a3.75,3.75,0,0,1,3.75,3.75v2.25h-1.5l-.75,6H18"
          fill="none"
          stroke={black}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M9.000 3.750 A3.000 3.000 0 1 0 15.000 3.750 A3.000 3.000 0 1 0 9.000 3.750 Z"
          fill="none"
          stroke={orange}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M17.25,13.5a5.25,5.25,0,0,0-10.5,0v2.25H9l.75,7.5h4.5l.75-7.5h2.25Z"
          fill="none"
          stroke={orange}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};
