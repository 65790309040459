export const homescreenOptions: { title: string; value: InitialHomescreen; id: string }[] = [
  {
    title: 'Dashboard (default)',
    value: 'dashboard',
    id: 'dashboardOption',
  },
  {
    title: 'Routes',
    value: 'routes',
    id: 'routesOption',
  },
  {
    title: 'Support',
    value: 'support',
    id: 'supportOption',
  },
  {
    title: 'News',
    value: 'news',
    id: 'newsOption',
  },
];
