import React, { ChangeEvent, FC } from 'react';
import './RadioOption.scss';

type RadioOptionProps = {
  title: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
};

export const RadioOption: FC<RadioOptionProps> = ({ title, value, onChange, checked }) => {
  return (
    <div className="optionContainer">
      <input type="radio" value={value} checked={checked} onChange={onChange} />
      <p className="radioOptionTitle">{title}</p>
    </div>
  );
};
