import React, { ChangeEvent, FC } from 'react';
import './RadioButtonRow.scss';

type RadioButtonProps = {
  title: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
};

export const RadioButtonRow: FC<RadioButtonProps> = ({ title, value, onChange, checked }) => {
  return (
    <div className="container radioUnderline">
      <p className="radioTitle">{title}</p>
      <input type="radio" value={value} checked={checked} onChange={onChange} />
    </div>
  );
};
