import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = { active?: boolean; size?: number };

export const SupportIcon: FC<Props> = ({ active = false, size = 32 }) => {
  const orange = active ? colors.orange : colors.inactiveGray;
  const black = active ? colors.black : colors.inactiveGray;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 23.0001C1.00101 21.5122 1.37072 20.0478 2.07608 18.7377C2.78143 17.4276 3.80044 16.3128 5.04199 15.4928C6.28354 14.6729 7.70893 14.1734 9.19073 14.039C10.6725 13.9046 12.1645 14.1395 13.5333 14.7227"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 6.5C4.5 7.95869 5.07946 9.35764 6.11091 10.3891C7.14236 11.4205 8.54131 12 10 12C11.4587 12 12.8576 11.4205 13.8891 10.3891C14.9205 9.35764 15.5 7.95869 15.5 6.5C15.5 5.04131 14.9205 3.64236 13.8891 2.61091C12.8576 1.57946 11.4587 1 10 1C8.54131 1 7.14236 1.57946 6.11091 2.61091C5.07946 3.64236 4.5 5.04131 4.5 6.5V6.5Z"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 23C15 25.1217 15.8429 27.1566 17.3431 28.6569C18.8434 30.1571 20.8783 31 23 31C25.1217 31 27.1566 30.1571 28.6569 28.6569C30.1571 27.1566 31 25.1217 31 23C31 20.8783 30.1571 18.8434 28.6569 17.3431C27.1566 15.8429 25.1217 15 23 15C20.8783 15 18.8434 15.8429 17.3431 17.3431C15.8429 18.8434 15 20.8783 15 23Z"
        stroke={black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 21.5C20.5 21.0055 20.6466 20.5222 20.9213 20.1111C21.196 19.7 21.5865 19.3795 22.0433 19.1903C22.5001 19.0011 23.0028 18.9516 23.4877 19.048C23.9727 19.1445 24.4181 19.3826 24.7678 19.7322C25.1174 20.0819 25.3555 20.5273 25.452 21.0123C25.5484 21.4972 25.4989 21.9999 25.3097 22.4567C25.1205 22.9135 24.8 23.304 24.3889 23.5787C23.9778 23.8534 23.4945 24 23 24"
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 26.9999C22.9011 26.9999 22.8044 27.0292 22.7222 27.0841C22.64 27.1391 22.5759 27.2172 22.5381 27.3085C22.5002 27.3999 22.4903 27.5004 22.5096 27.5974C22.5289 27.6944 22.5765 27.7835 22.6464 27.8534C22.7164 27.9234 22.8055 27.971 22.9025 27.9903C22.9994 28.0096 23.1 27.9997 23.1913 27.9618C23.2827 27.924 23.3608 27.8599 23.4157 27.7777C23.4707 27.6954 23.5 27.5988 23.5 27.4999C23.5002 27.4342 23.4874 27.3691 23.4623 27.3083C23.4372 27.2476 23.4004 27.1924 23.3539 27.1459C23.3075 27.0995 23.2523 27.0626 23.1915 27.0376C23.1308 27.0125 23.0657 26.9997 23 26.9999"
        stroke={orange}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
