import React, { FC } from 'react';
import { colors } from '../../styles/colors';

type Props = {
  size?: string;
};

export const PendingIcon: FC<Props> = ({ size = '25' }) => {
  const iconSize = `${size}px`;
  const borderRadius = parseInt(size, 10) * 0.5;

  return (
    <div
      style={{
        height: iconSize,
        width: iconSize,
        borderRadius,
        backgroundColor: colors.lightGray,
      }}
    />
  );
};
