import React, { Dispatch, FC, SetStateAction, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext/AuthContext';

type Props = {
  userType: UserRole;
  setUserType: Dispatch<SetStateAction<UserRole>>;
};

export const UserTypeToggle: FC<Props> = ({ userType, setUserType }) => {
  const {
    userInfo: {
      attributes: { role },
    },
  } = useContext(AuthContext);
  const buttonBaseStyle = 'flex-1 items-center rounded-full justify-center px-4';
  const textBaseStyle = 'leading-none h-full my-3';

  return (
    <div className="rounded-full bg-brand-light-gray flex flex-row p-1 w-full">
      {role === 'admin' && (
        <button
          className={` ${buttonBaseStyle} ${userType === 'admin' ? 'bg-brand-orange' : ''}`}
          onClick={() => setUserType('admin')}
          type="button"
        >
          <span className={`${textBaseStyle} ${userType === 'admin' ? 'text-white' : ''}`}>
            Pace Admin
          </span>
        </button>
      )}

      {(role === 'admin' || role === 'customer_admin') && (
        <button
          className={` ${buttonBaseStyle} ${
            userType === 'customer_admin' ? 'bg-brand-orange' : ''
          }`}
          onClick={() => setUserType('customer_admin')}
          type="button"
        >
          <span className={`${textBaseStyle} ${userType === 'customer_admin' ? 'text-white' : ''}`}>
            {role === 'admin' ? 'Customer Admin' : 'Admin'}
          </span>
        </button>
      )}

      {role === 'customer_admin' && (
        <button
          className={`${buttonBaseStyle} ${userType === 'customer_user' ? 'bg-brand-orange' : ''}`}
          onClick={() => setUserType('customer_user')}
          type="button"
        >
          <span className={`${textBaseStyle} ${userType === 'customer_user' ? 'text-white' : ''}`}>
            User
          </span>
        </button>
      )}
    </div>
  );
};
