import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import * as yup from 'yup';
import { UserTypeToggle } from './UserTypeToggle';
import { Button } from '../Button/Button';
import { FormikInput } from '../FormikInput/FormikInput';
import { FormLabel } from '../FormLabel/FormLabel';
import { CloseIcon } from '../../assets/icons';
import { OverlayContext } from '../../context/OverlayContext/OverlayContext';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import { useCustomers } from '../../hooks/useCustomers/useCustomers';
import { useUsers } from '../../hooks/useUsers/useUsers';

type DropdownProps = {
  disabled?: boolean;
  id: string;
  onChange: (value: ChangeEvent<HTMLSelectElement>) => void;
  options: Array<{ name: string; id: string }>;
  selectedCompanyId: string;
};

type FormValues = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  phone: string;
};

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phone: '',
};

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Please enter a first name'),
  lastName: yup.string().required('Please enter a last name'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  phone: yup
    .string()
    .min(14)
    .matches(/^(\(\d{3}\)[ ])\d{3}[-]\d{4}$/g, 'Please enter a valid phone number')
    .required('Please enter a phone number'),
});

export const Dropdown: FC<DropdownProps> = ({
  disabled,
  id,
  onChange,
  options,
  selectedCompanyId,
}) => {
  return (
    <div className="relative w-full">
      <select
        disabled={disabled}
        onChange={onChange}
        value={selectedCompanyId}
        className="rounded-full border border-solid border-brand-border-gray h-8 focus:shadow-outline focus:outline-none pl-4 pr-8 block w-full appearance-none"
      >
        <option value="" className="opacity-50">
          Select a company
        </option>
        {options.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
      <ErrorMessage name={id} component="span" className="text-sm text-brand-error" />
    </div>
  );
};

export const InviteUserForm: FC = () => {
  const {
    userInfo: {
      attributes: { role },
    },
  } = useContext(AuthContext);
  const [userType, setUserType] = useState<UserRole>(role === 'admin' ? 'admin' : 'customer_user');
  const [companyError, setCompanyError] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const { deactivateOverlay } = useContext(OverlayContext);
  const { associatedCompany, userInfo } = useContext(AuthContext);
  const { customers, fetchCustomers } = useCustomers();
  const { error: inviteError, loading, inviteUser } = useUsers();

  useEffect(() => {
    if (userInfo.attributes.role === 'admin') {
      fetchCustomers();
    }
  }, []);

  useEffect(() => {
    if (userType === 'admin') setSelectedCompanyId('');
  }, [userType]);

  const handleSubmit = (values: FormValues): void => {
    if (role === 'admin' && userType === 'customer_admin' && !selectedCompanyId) {
      setCompanyError(true);
      return;
    }

    inviteUser(
      {
        ...values,
        role: userType,
        customerId: userType === 'admin' ? '' : selectedCompanyId,
      },
      () => {
        deactivateOverlay();
        message.success('Your invitation was sent');
      },
    );
  };

  const onCompanySelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setCompanyError(false);
    setSelectedCompanyId(event.target.value);
  };

  const customerList = customers.map((customer) => ({
    id: customer.id,
    name: customer.attributes.name,
  }));

  return (
    <>
      <div className="flex flex-row justify-between items-center mb-4">
        <span className="font-bold text-xl block leading-none">Invite a User</span>
        <button type="button" onClick={() => deactivateOverlay()}>
          <CloseIcon size={12} />
        </button>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleBlur, handleChange }) => (
          <Form>
            <FormLabel title="Role" />
            <div className="w-full mb-4 lg:flex lg:flex-row">
              <UserTypeToggle userType={userType} setUserType={setUserType} />
            </div>

            <FormLabel title="First Name" required />
            <FormikInput id="firstName" />

            <FormLabel title="Last Name" required />
            <FormikInput id="lastName" />

            <FormLabel title="Phone number" required />
            <Field
              id="phone"
              name="phone"
              render={({ field }: { field: any }) => (
                <div className="w-full mb-4">
                  <MaskedInput
                    {...field}
                    id="phone"
                    name="phone"
                    mask={phoneNumberMask}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="tel"
                    className="rounded-full border border-solid border-brand-border-gray h-8 focus:shadow-outline focus:outline-none pl-4 block w-full"
                  />
                  <ErrorMessage
                    name="phone"
                    component="span"
                    className="text-sm text-brand-error"
                  />
                </div>
              )}
            />

            {userType !== 'admin' && (
              <>
                <FormLabel title="Company" required={role === 'admin'} />
                {role === 'admin' ? (
                  <Dropdown
                    id="company"
                    onChange={onCompanySelect}
                    options={customerList}
                    selectedCompanyId={selectedCompanyId}
                  />
                ) : (
                  <span>{associatedCompany.attributes.name}</span>
                )}
              </>
            )}

            <div className="mb-4">
              {companyError && <span className="text-brand-error">Please select a company</span>}
            </div>

            <FormLabel title="Email Address" required />
            <FormikInput id="email" />
            <div className="mb-4">
              {!!inviteError && <span className="text-brand-error">{inviteError}</span>}
            </div>

            <Button
              disabled={loading}
              type="submit"
              label={loading ? 'SENDING' : 'SEND INVITATION'}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};
