import React, { FC, useContext } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import paceLogo from '../../assets/images/paceLogo.png';
import { DashboardIcon, NewsIcon, RoutesIcon, SettingsIcon, SupportIcon } from '../../assets/icons';
import { UsersIcon } from '../../assets/icons/UsersIcon';
import { AuthContext } from '../../context/AuthContext/AuthContext';

type SidebarProps = {
  path: RouteComponentProps['path'];
};

type MenuItemProps = {
  active: boolean;
  icon?: React.ReactElement;
  menuItem: {
    label: string;
  };
};

export const MenuItem: FC<MenuItemProps> = ({ active, icon, menuItem }) => {
  const destination = `/${menuItem.label.toLowerCase()}`;

  return (
    <Link to={destination}>
      <div
        className={`my-2 py-2 px-8 rounded-full hover:cursor-pointer flex flex-row items-center ${
          active ? 'bg-white shadow-md' : ''
        }`}
      >
        <div className="mr-4">{icon}</div>
        <span
          className={`text-gray-600 opacity-100 hover:opacity-100 leading-none text-sm ${
            active ? 'font-bold text-gray-900' : ''
          }`}
        >
          {menuItem.label}
        </span>
      </div>
    </Link>
  );
};

export const Sidebar: FC<SidebarProps> = ({ path }) => {
  const {
    userInfo: {
      attributes: { role },
    },
  } = useContext(AuthContext);

  const isRouteActive = (label: string): boolean => label.toLowerCase() === path?.replace('/', '');

  const menuItems = [
    { label: 'Dashboard', icon: <DashboardIcon active={isRouteActive('Dashboard')} size={20} /> },
    { label: 'Routes', icon: <RoutesIcon active={isRouteActive('Routes')} size={20} /> },
    { label: 'Support', icon: <SupportIcon active={isRouteActive('Support')} size={20} /> },
    { label: 'News', icon: <NewsIcon active={isRouteActive('News')} size={20} /> },
    { label: 'Users', icon: <UsersIcon active={isRouteActive('Users')} size={20} /> },
    { label: 'Settings', icon: <SettingsIcon active={isRouteActive('Settings')} size={20} /> },
  ];

  return (
    <div className="hidden lg:block w-56 mr-8">
      <img
        src={paceLogo}
        alt="Pace logo"
        className="object-contain h-12 w-full object-left pl-8 mt-6 mb-16"
      />
      <div>
        {menuItems.map((menuItem) => {
          if (role === 'customer_user' && menuItem.label === 'Users') {
            return null;
          }

          return (
            <MenuItem
              active={isRouteActive(menuItem.label)}
              icon={menuItem.icon}
              key={menuItem.label}
              menuItem={menuItem}
            />
          );
        })}
      </div>
    </div>
  );
};
